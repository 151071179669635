import { createContext, useState, useContext } from 'react';
import backgroundImg from 'assets/img/home-background-min.jpg';

import homeTitle from 'assets/img/home-title.png';

import restart from 'assets/img/start_reload.png';

import startButtonFR from 'assets/img/start_continuer.png';
import startButtonFRHoover from 'assets/img/start_continuer_hoover.png';

import startButtonNL from 'assets/img/start.png';
import startButtonNLHoover from 'assets/img/start_hoover.png';

import emptySound from 'assets/sounds/empty.mp3';

import { LangContext } from 'langProvider';
import { HOMEPAGE, JUNIOR } from "routes";

export const AudioContext = createContext({
  init: false,
});

const audioLoaders = [
  { audio: new Audio(emptySound), available: true },
  { audio: new Audio(emptySound), available: true },
  { audio: new Audio(emptySound), available: true },
  { audio: new Audio(emptySound), available: true },
  { audio: new Audio(emptySound), available: true },
];

const audioLoading = [];

const startLoading = () => {
  if (audioLoading.length <= 0) return;

  const fileToLoad = audioLoading[0];
  // console.log(fileToLoad)
  const availableLoader = audioLoaders.findIndex((al) => al.available === true);

  if (availableLoader < 0) return;

  audioLoading.shift();

  audioLoaders[availableLoader].audio.src = fileToLoad.file;
  audioLoaders[availableLoader].available = false;
  audioLoaders[availableLoader].audio.load();

  // audioLoaders[availableLoader].audio.volume = 1

  audioLoaders[availableLoader].audio.addEventListener(
    'canplaythrough',
    (e) => {
      // console.log('can play thourgh', fileToLoad)
      // console.log('got loadeddata event', e, `${fileToLoad.name}_event`);
      audioLoaders[availableLoader].audio.pause();
      window.dispatchEvent(window[`${fileToLoad.name}_event`]);
      delete window[`${fileToLoad.name}_event`];
      audioLoaders[availableLoader].available = true;

      startLoading();
    },
    { once: true }
  );
};

export const AudioProvider = (props) => {
  //   const [audioLoading, setAudioLoading] = useState([]);
  const { changeLang } = useContext(LangContext);

  const setSource = (src) => {
    if (!audioState.audio.paused) {
      audioState.audio.pause();
    }
    audioState.audio.src = src;
  };

  const setLoop = (isLoop) => {
    audioState.audio.loop = isLoop;
  }

  const setBackgroundSource = (src, loop = false) => {
    if (!audioState.backgroundAudio) {
      audioState.backgroundAudio.pause();
    }
    audioState.backgroundAudio.src = src;
    audioState.backgroundAudio.loop = loop;
  };

  const setBackgroundAudioVolume = () => {
    audioState.backgroundAudio.volume = 0.5;
  };
  const loadSound = (soundName, file) => {
    // if (audioLoading.findIndex((al) => al.name === soundName) >= 0) {
    //   return;
    // }
    // setAudioLoading((prevAudioLoading) => [...prevAudioLoading, { name: soundName, file: file }]);
    audioLoading.push({ name: soundName, file: file });

    // if (audioLoading.findIndex((al) => al.name === soundName) >= 0) {
    //   console.log(soundName, ' in queue');
    // }

    window[`${soundName}_event`] = new Event(`${soundName}_loaded`);

    startLoading();
    return new Promise((resolve) => {
      window.addEventListener(
        `${soundName}_loaded`,
        () => {
          resolve();
        },
        { once: true }
      );
    });
  };

  const init = () => {
    if (audioState.init) {
      console.warn('Audio already init');
      return;
    }

    audioState.audio.volume = 0;
    audioState.audio
      .play()
      .catch(() => { })
      .finally(() => {
        audioState.audio.pause();
        audioState.audio.volume = 1;
        // audioState.audio.volume = 0;
        audioState.audio.loop = false;

        // add subtitles track
        audioState.audio.addTextTrack('captions');

        setAudioState((prevAudio) => {
          const newAudio = {
            ...prevAudio,
            init: true,
          };
          return newAudio;
        });
      });

    audioState.backgroundAudio.volume = 0;
    audioState.backgroundAudio
      .play()
      .catch(() => { })
      .finally(() => {
        audioState.backgroundAudio.pause();
        setBackgroundAudioVolume();
        // audioState.backgroundAudio.volume = .75;
      });

    audioLoaders.forEach((al, ind) => {
      al.audio.volume = 0;
      al.audio
        .play()
        .catch(() => { })
        .finally(() => {
          al.audio.pause();
        });
    });
  };

  const [audioState, setAudioState] = useState({
    init: false,
    audio: new Audio(emptySound),
    backgroundAudio: new Audio(emptySound),
    setSource,
    setLoop,
    setBackgroundSource,
    setBackgroundAudioVolume,
    loadSound,
    initiator: init,
  });

  const setLangAndInit = (lang) => {
    changeLang(lang);
    init();
  };

  const showChildren = [HOMEPAGE, JUNIOR].includes(window.location.pathname)

  return (
    <div className={`audio-container ${audioState.init || showChildren ? '' : 'request'}`}>
      <div
        className="cursor-gray audio-container-page fixed-height fixed-width background-cover flex flex-column justify-content-center align-items-center"
        style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        {false && (
          <div className="title">
            <img src={homeTitle} alt="Walk in my shoes" />
          </div>
        )}

        <div className="start-container flex justify-content-center align-items-center">
          <button className="start-button flex-order-1" onClick={() => setLangAndInit('fr')}>
            <img className="base-image" src={startButtonFR} alt="" />
            <img className="hover-image" src={startButtonFRHoover} alt="" />
            {/* <div className="background-cover s" style={{ backgroundImage: `url(${startButtonFR})` }}>
            </div> */}
          </button>
          <button className="start-button flex-order-3" onClick={() => setLangAndInit('nl')}>
            <img className="base-image" src={startButtonNL} alt="" />
            <img className="hover-image" src={startButtonNLHoover} alt="" />
            {/* <div className="background-cover " style={{ backgroundImage: `url(${startButtonNL})` }}>
            </div> */}
          </button>
          <div className="start-button arrow-background flex-order-2">
            <img className="fixed-image" src={restart} alt="" />
          </div>
        </div>
      </div>

      <AudioContext.Provider value={audioState}>{(audioState.init || showChildren) && props.children}</AudioContext.Provider>
    </div>
  );
};
