import { createContext, useContext, useState } from 'react';
import { stories } from 'assets/stories';

import { AudioContext } from 'audioProvider';

import crossWhite from 'assets/img/cross-white.svg';
import squareWhiteBackground from 'assets/img/info-white-background.png';
import scrollBarBackground from 'assets/img/scrollBar-min.png';
import scrollBarHandle from 'assets/img/scollBarHandle-min.png';

export const LoadAssetsContext = createContext({
  loaded: false,
  changeJourney: () => {},
});

export const LoadAssetsProvider = (props) => {
  const [loaded, setLoaded] = useState({});
  const [journey, setJourney] = useState();
  const [files, setFiles] = useState([
    { type: 'img', name: 'crossWhite', src: crossWhite },
    { type: 'img', name: 'squareWhiteBackground', src: squareWhiteBackground },
    { type: 'img', name: 'scrollBarBackground', src: scrollBarBackground },
    { type: 'img', name: 'scrollBarHandle', src: scrollBarHandle }
  ]);

  const audioContext = useContext(AudioContext);

  const isLoaded = () => {
    for (let e in loaded) {
      if (!loaded[e]) return false;
    }
    return true;
  };

  const changeJourney = (name, lang) => {
    if (journey === name) return;
    if (!lang) return;

    if (!(name in stories[lang])) return;

    const fileToLoad = [];
    const loadingState = {};

    stories[lang][name].steps.forEach((step, index) => {
      if (step.background && step.background.src) {
        const filename = `${name}_background_${index}`;

        loadingState[filename] = false;
        fileToLoad.push({ type: 'img', name: filename, src: step.background.src });
      }
      if (step.mapImage) {
        const filename = `${name}_map_${index}`;

        loadingState[filename] = false;
        fileToLoad.push({ type: 'img', name: filename, src: step.mapImage });
      }
      if (step.sound) {
        const filename = `${name}_sound_${index}`;
        audioContext.loadSound(filename, step.sound).then(() => {
          fileLoaded(filename);
        });

        loadingState[filename] = false;
      }
    });

    if (stories[lang][name].additionalLoad) {
      stories[lang][name].additionalLoad.forEach((addLoad, index) => {
        if (addLoad.type === 'img') {
          const filename = `${name}_additional_${index}`;

          loadingState[filename] = false;
          fileToLoad.push({ type: 'img', name: filename, src: addLoad.src });
        }
      });
    }

    setJourney(name);
    setLoaded(loadingState);

    setTimeout(() => {
      setFiles(fileToLoad);
    }, 150);
  };

  const fileLoaded = (filename) => {
    // console.log('loaded', filename);
    setFiles((prevFiles) => prevFiles.filter((f) => f.name !== filename));
    setLoaded((prevLoaded) => ({ ...prevLoaded, [filename]: true }));
  };

  // console.log(files)
  return (
    <>
      <div className="file-loader">
        {files.map((f) => {
          return <div key={f.name}>{f.type === 'img' && <img src={f.src} alt="" onLoad={() => fileLoaded(f.name)} onError={() => fileLoaded(f.name)} />}</div>;
        })}
      </div>
      <LoadAssetsContext.Provider value={{ loaded: isLoaded(), changeJourney }}>{props.children}</LoadAssetsContext.Provider>
    </>
  );
};
