import { hassan_fr } from './hassan_fr';
import { hassan_nl } from './hassan_nl';

import { sifa_fr } from './sifa_fr';
import { sifa_nl } from './sifa_nl';

import { senait_fr } from './senait_fr';
import { senait_nl } from './senait_nl';
import { senait_junior_fr } from './senait_junior_fr';
import { senait_junior_nl } from './senait_junior_nl';

export const stories = {
  fr: {
    senait: senait_fr,
    hassan: hassan_fr,
    sifa: sifa_fr,
    senait_junior: senait_junior_fr
  },
  nl: {
    senait: senait_nl,
    hassan: hassan_nl,
    sifa: sifa_nl,
    senait_junior: senait_junior_nl
  },
};
