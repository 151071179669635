import './App.css';

import { CustomRouting } from 'routes.js';
import { RotateScreen } from './components/rotateScreen';

import { PageTransitionProvider } from './pageTransitionProvider';
import { LoadAssetsProvider } from './loadAssetsProvider';

// import styled from 'styled-components';
// import ScrollIntoView from './scrollIntoView';

// import { useEffect, useRef } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import plusButton from 'assets/img/icon_plus.png';
import plusHoverButton from 'assets/img/icon_plus_hoover.png';

// import { useEffect } from 'react';
// import ReactGA from 'react-ga';

// const MouseCatcher = styled.div`
//   height: 100%;
//   width: 100%;
// `;
// const MouseTracker = styled.div`
//   position: fixed;
//   z-index: 9999;
//   height: 50px;
//   width: 50px;
//   border-radius: 50%;
//   transform: translateX(-50%) translateY(-50%);
//   pointer-events: none;
//   background-image:url('./assets/img/curseur_souris.png');
//   background-size:contain;
//   background-repeat: no-repeat;
//   background-position:center;
//   background-color:white;
// `;

function App() {
  // const containerRef = useRef(null);

  // const [cursorPosition, setCursorPosition] = useState({
  //   x: 0,
  //   y: 0,
  // });

  // const handleMouseMove = (e) => {
  //   const { clientX, clientY } = e;
  //   setTimeout(() => {
  //     setCursorPosition({
  //       x: clientX,
  //       y: clientY,
  //     });
  //   }, 25);
  // };

  // const mouseTracker = useRef();
  // const handleMouseMove = (e) => {
  //   const { clientX, clientY } = e;

  //   setTimeout(() => {
  //     mouseTracker.current.style.left = `${clientX}px`;
  //     mouseTracker.current.style.top = `${clientY}px`;
  //   }, 25);
  // };
  // useEffect(() => {
  //   const mouseCatcher = document.getElementById('mouseCatcher');

  //   mouseCatcher.addEventListener('mousemove', handleMouseMove);
  //   return ()=>{
  //     mouseCatcher.removeEventListener('mousemove', handleMouseMove)
  //   }
  // }, [mouseTracker]);

  // useEffect(() => {
  //   // initialize GA
  //   console.log('initialize GA', ReactGA)
  //   ReactGA.initialize('G-7M90ZKRG58', {debug: false,});

  // }, []);

  return (
    <RotateScreen>
      <LoadAssetsProvider>
        <PageTransitionProvider>
          <div style={{ display: 'none' }}>
            <img src={plusButton} alt="+" />
            <img src={plusHoverButton} alt="+" />
          </div>
          {/* <MouseCatcher id="mouseCatcher"> */}
          {/* onMouseMove={handleMouseMove} */}
          {/* <div ref={mouseTracker} id="mouseTracker" className="mouse-tracker"></div> */}

          {/* <Router basename={"/wims"}> */}
          <Router>
            {/* <ScrollIntoView element={containerRef.current}>
            <div ref={containerRef} className="fixed-height fixed-width with-scroll"> */}
            <CustomRouting />
            {/* </div>
          </ScrollIntoView> */}
          </Router>
          {/* </MouseCatcher> */}
        </PageTransitionProvider>
      </LoadAssetsProvider>
    </RotateScreen>
  );
}

export default App;
