import React, { createContext, useState, useEffect, useCallback } from 'react';

export const PageTransitionContext = createContext();

// - Move ou fold from left to right quand on transitionne d’une séquence à l’autre (pour la sensation de linéarité)
// - Fold from top to bottom quand on transitionne entre une séquence et un écran de choix (et vise versa) + entre une carte et la séquence qui suit
// - Fall entre un écran de choix et un épilogue (pour accentuer la sensation de choix final, de non retour)

export function getNextTransition(nextType, currentType) {
  // if (!nextType) return 'moveToLeftFromRight';

  let nextPageTransition='';
  let nextEnterAnimation = '';
  let nextExitAnimation = '';//scaleDownCenter
  switch (nextType) {
    case 'info':
    case 'choice':
      nextPageTransition = 'fadeFromTop';
      if (['info', 'choice'].indexOf(currentType) >= 0) {
        nextPageTransition = '';//'moveToTopFromBottom';
        nextEnterAnimation = '';
        nextExitAnimation = '';
      }
      break;
    case 'map':
      nextPageTransition = 'fadeFromTop';
      // if (['info', 'choice','map'].indexOf(currentType) >= 0) {
      //   nextPageTransition = '';//'moveToTopFromBottom';
      //   nextEnterAnimation = '';
      //   nextExitAnimation = '';
      // }
      break;

    case 'epilogue':
      nextPageTransition = 'scaleUpScaleUp';
      nextEnterAnimation = '';
      nextExitAnimation = '';
      break;
    case 'sequence':
      switch (currentType) {
        case 'info':
        case 'choice':
        case 'map':
          nextPageTransition = 'fadeFromBottom';//'moveToTopFromBottom';
          break;
        default:
          nextPageTransition = 'fadeFromRight';//'moveToLeftFromRight';
          break;
      }
      break;

    case 'about':{
      nextPageTransition = 'fadeFromBottom';
      break;
    }
    default:
      nextPageTransition = 'fadeFromRight';//'moveToLeftFromRight';
      nextEnterAnimation = '';
      nextExitAnimation = '';

      if (currentType === "about"){
        nextPageTransition = 'fadeFromTop';//'moveToLeftFromRight';
      }
  }

  return { nextPageTransition, nextEnterAnimation, nextExitAnimation };
}

export function PageTransitionProvider({ children }) {
  // const [animation, setAnimation] = useState({...all_animations.default})

  // const resolverRef = useRef(null);
  const [resolverRef, setResolverRef] = useState(null);
  const [preset, setPreset] = useState(''); //moveToLeftFromRight
  const [enterAnimation, setEnterAnimation] = useState('moveFromRight');
  const [exitAnimation, setExitAnimation] = useState('');

  useEffect(() => {
    // console.log('effect shoot', resolverRef);
    if (resolverRef) {
      // console.log('resolverRef');
      resolverRef(preset);
      setResolverRef(null);
    }
    /**
     * Since a state update could be triggered with the exact same state again,
     * it's not enough to specify state as the only dependency of this useEffect.
     * That's why resolverRef.current is also a dependency, because it will guarantee,
     * that handleSetState was called in previous render
     */
  }, [resolverRef, preset]);

  const handleSetAnimation = useCallback(
    (stateAction) => {
      // const newAnimation = all_animations[stateAction] ||all_animations.default;
      setPreset(stateAction.nextPageTransition);
      setEnterAnimation(stateAction.nextEnterAnimation);
      setExitAnimation(stateAction.nextExitAnimation);

      return new Promise((resolve) => {
        // resolverRef.current = resolve;
        setResolverRef(resolve);
      });
    },
    [setPreset]
  );

  return (
    <PageTransitionContext.Provider
      value={{
        preset,
        enterAnimation,
        exitAnimation,
        setPreset,
        handleSetAnimation,
        // setDefault,
        // setTopFromBottom,
        // setBottomFromTop,
        // setFall,
      }}
    >
      {children}
    </PageTransitionContext.Provider>
  );
}
