import { useContext } from 'react';
import backgroundImg from 'assets/img/home-background-min.jpg';

import homeTitle from 'assets/img/home-title.png';
import homeTitleJunior from 'assets/img/home-title-junior.png';

import FRButton from 'assets/img/FR.png';
import NLButton from 'assets/img/NL.png';

import { LangContext } from 'langProvider';
import { JuniorContext } from 'juniorProvider';
import './style.css';

import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';

import { useHistory } from 'react-router-dom';
import * as routes from 'routes';

import { AudioContext } from 'audioProvider';

import avisButton from 'assets/img/mon_avis.png';
import avisButtonHover from 'assets/img/mon_avis_hoover.png';
import ReactGA from 'react-ga4';

export const Homepage = (props) => {
  const { lang, changeLang } = useContext(LangContext);
  const { junior, changeJunior } = useContext(JuniorContext);

  const history = useHistory();

  const { init, initiator } = useContext(AudioContext);

  const pageTransitionContext = useContext(PageTransitionContext);

  const setLang = (lang) => {
    changeLang(lang);
    if (!init) {
      initiator();
    }

    ReactGA.event({
      category: 'Lang',
      action: `${lang}`,
    });

    const nextPageLocation = routes.CHOOSE;
    const nextPageTransition = getNextTransition();
    // console.log(nextPageTransition)
    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(nextPageLocation);
    });
    // history.push(routes.CHOOSE);
  };

  const openAbout = () => {
    const nextPageTransition = getNextTransition('about');
    // console.log(nextPageTransition)
    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(routes.ABOUT);
    });
  };

  const goReview = () => {
    const nextPageTransition = getNextTransition();

    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(routes.REVIEW, { name: 'homepage' });
    });
  };

  // let buttonClassicText;
  // let buttonJuniorText;

  // switch (lang) {
  //   case ("nl"):
  //     buttonClassicText = "Klassieke versie (> 15 jaar)"
  //     buttonJuniorText = "Junior versie"
  //     break
  //   case ("fr"):
  //   default:
  //     buttonClassicText = "Version classique (> 15 ans)"
  //     buttonJuniorText = "Version junior"
  // }

  // buttonClassicText = <div>Version classique (> 15 ans)</div>


  return (
    <main className=" main-homepage fixed-height full-width background-cover" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="content-container flex flex-column justify-content-center align-items-center">
        <div className="align-self-end">
          <button className="choose-junior" onClick={() => { changeJunior(!junior) }}>
            {/* {junior ? buttonClassicText : buttonJuniorText} */}
            {
              junior ?
                <>
                  <div>
                    Version classique ({'>'} 15 ans)
                  </div><div>
                    Klassieke versie ({'>'} 15 jaar)
                  </div>
                </>
                :
                <>
                  <div>
                    Version junior
                  </div><div>
                    Junior versie
                  </div>
                </>
            }
          </button>
        </div>
        <div className="title">
          <img src={junior ? homeTitleJunior : homeTitle} alt="Walk in my shoes" />
        </div>

        <div className="choose-lang-container">
          <button className="choose-lang-button" onClick={() => setLang('fr')}>
            <div className="background-cover circular-button">
              <img src={FRButton} alt="FR" />
            </div>
            <div className="choose-lang-text">
              Un récit interactif
              <br />
              basé sur des histoires vécues
              <br />
              de réfugié.e.s
            </div>
          </button>

          <button className="choose-lang-button" onClick={() => setLang('nl')}>
            <div className="background-cover circular-button">
              <img src={NLButton} alt="NL" />
            </div>
            <div className="choose-lang-text">
              Een interactieve tocht
              <br />
              gebaseerd op echte verhalen
              <br />
              van vluchtelingen
            </div>
          </button>
        </div>

        <div className="more-container flex ">
          <button className="more-button " onClick={openAbout}>
            <div className="background-cover small-circular-button more-button"></div>
          </button>

          {lang && !junior && (
            <button className="with-hover go-to-review " onClick={goReview}>
              <img src={avisButton} alt="" /> <img className="on-hover" src={avisButtonHover} alt="" />
            </button>
          )}
        </div>
      </div>
    </main >
  );
};
