import './style.css';
import { useContext, useEffect, useRef } from 'react';

import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';

import { useHistory } from 'react-router-dom';
import * as routes from 'routes';

import FRButton from 'assets/img/FR.png';
import NLButton from 'assets/img/NL.png';

import backgroundImg from 'assets/img/home-background-min.jpg';
import crossWhite from 'assets/img/cross-white.svg';

import caritasLogo from 'assets/img/Caritas_int_be_rouge_blanc@2x.png';
import justicePaixLogo from 'assets/img/20180124_Justice_Paix_Logo_RVB-Positif@2x.png';

import switchLogo from 'assets/img/switch-logo.svg';
import CBD_logo_NL from 'assets/img/CBD_logo_NL_white@2x.png';
import CBD_logo_FR from 'assets/img/CBD_logo_FR_white@2x.png';

export const About = (props) => {
  const history = useHistory();

  const pageTransitionContext = useContext(PageTransitionContext);

  const about_container = useRef();

  const goBack = () => {
    const nextPageTransition = getNextTransition(null, 'about');
    // console.log(nextPageTransition)
    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      if (history.action === 'POP') {
        // maybe no back page
        history.replace(routes.HOMEPAGE);
      } else {
        history.goBack();
      }
    });
  };

  useEffect(() => {
    if (about_container.current) about_container.current.focus();
  });
  return (
    <div ref={about_container} tabIndex="1" style={{ height: '100%', overflowY: 'auto' }}>
      <div className="fixed-height fixed-width background-cover about-background-image" style={{ backgroundImage: `url(${backgroundImg})` }}></div>

      <main id="about" className=" cursor-gray full-height full-width background-cover flex flex-column justify-content-center align-items-center">
        <div className="scroll-indicator"></div>
        <div className="button-go-back">
          <button className="close" onClick={goBack} color="primary">
            <img className="close-icon" src={crossWhite} alt="" />
          </button>
        </div>

        <div className="content flex flex-column justify-content-center">
          <section>
            <h1 className="title">
              À propos / Over
              <br />
              &ldquo; Walk in my shoes &rdquo;
            </h1>
            <div className="text">
              <div className="background-cover circular-button">
                <img src={FRButton} alt="FR" />
              </div>
              “Walk in my shoes” est un « serious game » numérique développé principalement pour les jeunes du secondaire (2e et 3e degrés). L’objectif est de traiter le sujet complexe de la
              migration, des conflits dans le monde et des expériences des réfugié.e.s de manière compréhensible, interactive, ainsi que de stimuler l’empathie et de lutter contre la polarisation.
              <br />
              <br />
              En tant que joueur.euse, vous choisissez un personnage: Hassan (Syrie), Sifa (République démocratique du Congo) ou Senait (Érythrée). Vous vous mettez dans leurs chaussures et découvrez
              leur histoire. Au fil des événements, des choix difficiles sont à poser. Pour chaque dilemme, des sources d’information sont proposées (la vidéo d’un témoin, un article…) pour vous aider
              à prendre une décision.
              <br />
              <br />
              Depuis 2023, il existe également une version « Junior » pour les élèves de troisième degré primaire. Cette version ne présente qu'un personnage, Senait, dont l’histoire est lue par l'enseignant-e (disponible sur le site web de <a href="https://www.caritasinternational.be/fr/projects/education/outil-digital-walk-in-my-shoes-junior/" target="_blank" rel="noreferrer">Caritas International</a>).
              <br />
              <br />
              “Walk in my shoes” a été développé en collaboration avec des jeunes ayant un parcours de réfugié. Les histoires qui sont racontées sont inspirées de leurs expériences.
            </div>
            <div className="text">
              <div className="background-cover circular-button">
                <img src={NLButton} alt="NL" />
              </div>
              “Walk in my shoes” is een digitale “Serious game” die in de eerste plaats is ontwikkeld voor jongeren uit de 2de en 3de graad secundair onderwijs. Het opzet is om enerzijds de complexe
              materie van migratie, conflict en vluchtelingen op een begrijpbare en interactieve manier aan te bieden en anderzijds empathie te stimuleren en polarisatie tegen te gaan.
              <br />
              <br />
              Als speler kies je een personage: Hassan (Syrië), Sifa (Congo) of Senait (Eritrea). Vervolgens plaats je je in hun schoenen en ontdek je hun verhaal. Er zullen moeilijke keuzes moeten
              worden gemaakt. Voor elk dilemma wordt er meer achtergrondinformatie gegeven (een filmpje van een getuigenis, een artikel,…) om te helpen een beslissing te nemen.
              <br />
              <br />
              Sinds 2023 bestaat er ook een  « Junior » versie voor leerlingen in de 3e graad basisonderwijs. In deze versie volg je één personage, namelijk Senait, en lees je als leraar zelf het verhaal voor (beschikbaar op de website van <a href="https://www.caritasinternational.be/nl/projects/educatie/lesmateriaal-walk-in-my-shoes-junior/" target="_blank" rel="noreferrer">Caritas International</a>).
              <br />
              <br />
              “Walk in my shoes” werd ontwikkeld in samenwerking met jongeren met een vluchtverhaal. De verhalen die worden verteld zijn grotendeels gebaseerd op hun parcours.
            </div>
          </section>

          {/* <section>
          Contact : > Infos :                                                                                      Contact : > Info :
Matériel pédagogique / Pedagogisch materiaal                               Matériel pédagogique
educ@caritasint.be                                                                                  info@justicepaix.be
            <h2 className="title">Un projet de / Een projet van</h2>
            <div className="flex justify-content-center two-columns"></div>
          </section> */}
          <section>
            <h2 className="title">Un projet de / Een projet van</h2>
            <div className="flex justify-content-center two-columns">
              <div className="col">
                <img className="logo" src={caritasLogo} alt="Caritas" />
                <p>
                  FR
                  <br />
                  Caritas International Belgique est une ONG qui vient en aide aux victimes de guerres, violences, de catastrophes et de la pauvreté, dans leur propre pays ou en fuite, en
                  collaboration avec ses partenaires. En Belgique, Caritas soutient les personnes migrantes, et œuvre à la sensibilisation et mobilisation de citoyens, politiciens et jeunes.
                  <br />
                  <br />
                  {/* INFOS:
                  <br />
                  <a href="https://www.caritasinternational.be/fr/projects/education/outil-walk-in-my-shoes/" target='_blank' rel='noreferrer'>Matériel pédagogique</a>
                  <br/>
                  <a href="mailto:educ@caritasint.be">educ@caritasint.be</a>
                  <br/>
                  <br /> */}
                  NL
                  <br />
                  Caritas International België is een NGO die hulp biedt aan slachtoffers van geweld, natuurrampen en armoede, in hun eigen land of op de vlucht. Dit in samenwerking met haar partners.
                  In België ondersteunt Caritas nieuwkomers en werkt aan het sensibiliseren en mobiliseren van burgers, politici en jongeren.
                  <br />
                  <br />
                  INFO
                  <br />
                  <a href="https://www.caritasinternational.be/fr/projects/education/outil-walk-in-my-shoes/" target='_blank' rel='noreferrer'>Matériel pédagogique</a> / <a href="https://www.caritasinternational.be/nl/projects/educatie/lesmateriaal-walk-in-my-shoes/" target='_blank' rel='noreferrer'>Pedagogisch materiaal</a>
                  <br />
                  <a href="mailto:educ@caritasint.be">educ@caritasint.be</a>
                </p>
              </div>
              <div className="col">
                <img className="logo" src={justicePaixLogo} alt="Caritas" />
                <p>
                  FR
                  <br />
                  Justice et Paix est une ONG et une organisation d’éducation permanente belge qui conscientise et interpelle les citoyen.ne.s, les acteur.rice.s éducatif.ves et scolaires et les
                  responsables politiques sur les questions de conflits, de démocratie et d’environnement.
                  <br />
                  <br />
                  NL
                  <br />
                  Justice et Paix is een Belgische Franstalige NGO en een educatieve organisatie die burgers, onderwijsactoren en politici sensibiliseert over thema’s als conflict, democratie en
                  milieu.
                  <br />
                  <br />
                  INFOS:
                  <br />
                  <a href="https://www.justicepaix.be/walk-in-my-shoes/" target='_blank' rel='noreferrer'>Matériel pédagogique</a>
                  <br />
                  <a href="mailto:info@justicepaix.be">info@justicepaix.be</a>
                </p>
              </div>
            </div>
            <div className="flex justify-content-center two-columns">
              <div className="col">
                <p className="uppercase">Une production / Een productie</p>
                <img className="logo small" src={switchLogo} alt="Switch" />
              </div>
              <div className="col">
                <p className="uppercase">Avec le soutien de / Met de steun van</p>
                <img className="logo small" src={CBD_logo_FR} alt="CBD" />
                <img className="logo small" src={CBD_logo_NL} alt="CBD" />
              </div>
            </div>
          </section>

          <section>
            <h2 className="title">Crédits / Copyright</h2>

            <div className="flex justify-content-center two-columns">
              <div className="col">
                <span className="uppercase">
                  Coodination & écriture /<br />
                  coördinatie & redactie
                </span>{' '}
                <br />
                Ariane Dewandre,
                <br />
                Steffi Vermeire,
                <br />
                Claire Mathot
                <br />
                <br />
                <span className="uppercase">
                  Coordination & production /<br />
                  coördinatie & productie
                </span>{' '}
                <br />
                Antonella Lacatena,
                <br />
                Sophie De Brabandere
                <br />
                <br />
                <span className="uppercase">DESIGN & ILLUSTRATION / DESIGN & ILLUSTRATIE</span>
                {/* <br />
              Design & illustration / design & illustratie */}
                <br />
                Marion Doumecq Lacoste
                <br />
                <br />
                <span className="uppercase">Développement web / web developpement</span>
                <br />
                Arnaud Browet
                <br />
                <br />
                <span className="uppercase">Vidéos / video’s</span>
                <br />
                Mortimer Petre
              </div>
              <div className="col">
                <div className="flex flex-column" style={{ height: '100%' }}>
                  <div style={{ flex: 1 }}>
                    <span className="uppercase">Son / geluid</span>
                    <br />
                    Luc Laret
                    <br />
                    <br />
                    <span className="uppercase">Voix off FR / voice-over FR</span>
                    <br />
                    Morgane Heugens,
                    <br />
                    Emma Soto,
                    <br />
                    Julien De Wispelaere
                    <br />
                    <br />
                    <span className="uppercase">Voix off NL / voice-over NL</span>
                    <br />
                    Berdien Schepers,
                    <br />
                    Yana Steenhout,
                    <br />
                    Pieter De Brabandere
                  </div>
                  <div>© 2021 / WALK IN MY SHOES</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};
