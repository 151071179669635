import './style.css';

import homeImg from 'assets/img/home-icon.png';
import homeHoverImg from 'assets/img/home-icon-hoover.png';

import { useHistory } from 'react-router-dom';
import * as routes from 'routes';
import { useContext, useMemo, useState, useRef, useEffect, forwardRef } from 'react';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';

import { CSSTransition } from 'react-transition-group';

// import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Fade from '@material-ui/core/Fade';
// import Slide from '@material-ui/core/Slide';

import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import baseBackgroundImg from 'assets/img/home-background-min.jpg';
import closeBlack from 'assets/img/close-black.png';


const TransitionFade = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={{ enter: 1000, exit: 1000 }} />;
});
// const TransitionSlideUp = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export const Choice = (props) => {
  const { content } = props;
  const history = useHistory();
  const pageTransitionContext = useContext(PageTransitionContext);

  const contentRef = useRef();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState({});
  const [showQuestion, setShowQuestion] = useState(true);

  const [showActions, setShowActions] = useState(false);

  const [disabledButtons, setDisabledButtons] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const tt = setTimeout(() => {
      setShowActions(true);
    }, 2000);
    return () => {
      clearTimeout(tt);
    };
  }, []);

  /* Styles */
  const sequenceContainer = {
    // backgroundImage: `url(${content.background})`,
    ...(content.containerStyle || {}),
  };
  const fixedImageBackgroundStyle = {
    backgroundImage: `url(${content.fixedImageBackground || ''})`,
    ...(content.fixedImageBackgroundStyle || {}),
  };
  const contentStyle = {
    ...(content.contentStyle || {}),
  };
  const contentTextStyle = {
    ...(content.contentTextStyle || {}),
  }

  const goNextStep = useMemo(() => {
    return (nextStep) => {
      const nextStepIndex = props.allSteps.findIndex((step) => step.id === nextStep);

      let nextPageLocation, nextPageTransition;

      let state = {};
      if (nextStepIndex < 0) {
        if (nextStep === '-1') {
          nextPageLocation = routes.OTHERSTORIES;
          nextPageTransition = getNextTransition();
          state = {
            name: props.name
          };
        }
        else {
          nextPageLocation = routes.HOMEPAGE;
          nextPageTransition = getNextTransition();
        }

      } else {
        nextPageLocation = `${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`;
        nextPageTransition = getNextTransition(props.allSteps[nextStepIndex].type, 'choice');
      }

      pageTransitionContext.handleSetAnimation(nextPageTransition).then((value) => {
        history.push(nextPageLocation, state);
      });

      // if (nextStepIndex < 0) history.push(routes.HOMEPAGE);
      // else history.push(`${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`);
    };
  }, [history, props.allSteps, props.name, pageTransitionContext]);

  const goBackHome = () => {
    const nextPageTransition = getNextTransition();

    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(routes.HOMEPAGE);
    });
  };

  // background-image: url(pulic/img/border.png);
  return (
    <div id="choice" ref={contentRef} className="background-cover flex flex-column " style={sequenceContainer}>
      {content.fixedImageBackground && <div className="fixed-background background-cover" style={fixedImageBackgroundStyle}></div>}
      {props.lang && (
        <>
          <div className="control-container flex">
            <button className="go-back-home-button" onClick={goBackHome}>
              <span className="info">{'<'}&nbsp;Home </span> <img src={content.homeIcon || homeImg} alt="" /> <img className="on-hover" src={content.homeHoverIcon || homeHoverImg} alt="" />
            </button>
          </div>

          {/* justify-content-space-between */}
          <div className="content flex flex-column " style={contentStyle}>
            {/* <CSSTransition in={!showConfirmation} timeout={0} classNames="question" unmountOnExit> */}
            {showQuestion && (
              <>
                <div className="content-text" dangerouslySetInnerHTML={{ __html: content.contentText }} style={contentTextStyle}></div>
                <CSSTransition in={showActions} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                  <div className="actions-container">
                    <div className="actions flex flex-row justify-content-center flex-wrap">
                      {content.choices &&
                        content.choices.map((act, ind) => {
                          const button_key = act.key || ind;
                          if (act.type === 'button') {
                            let clickAction = () => {
                              console.warn('Unkown action!!');
                            };
                            if (act.action === 'showConfirmation') {
                              clickAction = () => {
                                contentRef.current.scrollTop = 0;
                                setConfirmationContent(act.confirmationContent);
                                setShowConfirmation(true);
                              };
                            } else if (act.action === 'goNext') {
                              clickAction = () => {
                                goNextStep(act.nextStepId);
                              };
                            } else if (act.action === 'fakeChoice') {
                              clickAction = () => {
                                setDialogContent({
                                  text: act.actionText || '',
                                  style: act.actionTextStyle || {},
                                });
                                handleDialogOpen();
                                setDisabledButtons((prevDisabled) => [...prevDisabled, button_key]);
                              };
                            }
                            return (
                              <CustomButton
                                key={button_key}
                                isDisabled={disabledButtons.indexOf(button_key) >= 0}
                                onClick={clickAction}
                                customstyle={act.buttonStyle || {}}
                                className="action-button bloc-noir"
                              >
                                <div className="icon">
                                  {act.icon && <img src={act.icon} alt="" />}
                                  {act.iconHover && <img className="on-hover" src={act.iconHover} alt="" />}
                                </div>
                                <div>{act.text}</div>
                              </CustomButton>
                            );
                          }
                          return <></>;
                        })}
                    </div>
                  </div>
                </CSSTransition>
              </>
            )}

            <CSSTransition in={showConfirmation} timeout={1000} classNames="confirmation" unmountOnExit onEnter={() => setShowQuestion(false)} onExited={() => setShowQuestion(true)}>
              <div className="transition-container flex flex-column">
                <div className="content-text" dangerouslySetInnerHTML={{ __html: (confirmationContent.title && confirmationContent.title) || '' }}></div>

                <div className="actions-container">
                  <div className="actions flex flex-row justify-content-center flex-wrap">
                    {confirmationContent.buttons &&
                      confirmationContent.buttons.map((act, ind) => {
                        let clickAction = () => {
                          console.warn('Unkown action!!');
                        };

                        if (act.action === 'closeConfirmation') {
                          clickAction = () => {
                            contentRef.current.scrollTop = 0;
                            // setConfirmationContent({});
                            setShowConfirmation(false);
                          };
                        } else if (act.action === 'goNext') {
                          clickAction = () => {
                            goNextStep(act.nextStepId);
                          };
                        }
                        else if (act.action === 'fakeChoice') {

                          clickAction = () => {
                            contentRef.current.scrollTop = 0;
                            setShowConfirmation(false);

                            setDialogContent({
                              text: act.actionText || '',
                              style: act.actionTextStyle || {},
                            });
                            handleDialogOpen();
                            setDisabledButtons((prevDisabled) => [...prevDisabled, act.buttonKey]);
                          };
                        }
                        return (
                          <CustomButton key={act.key || ind} onClick={clickAction} customstyle={act.buttonStyle || {}} className="action-button bloc-noir">
                            <div className="icon">
                              {act.icon && <img src={act.icon} alt="" />}
                              {act.iconHover && <img className="on-hover" src={act.iconHover} alt="" />}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: (act.text && act.text) || '' }}></div>
                          </CustomButton>
                        );
                      })}
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </>
      )}

      <CustomDialog lang={props.lang} open={dialogOpen} dialogContent={dialogContent} close={handleDialogClose}></CustomDialog>
    </div>
  );
};

const CustomButton = (props) => {
  const customStyle = makeStyles((theme) => ({
    customButton: props.customstyle || {},
  }))();

  return (
    // <div className={}>
    <button disabled={props.isDisabled} onClick={props.onClick} className={clsx(customStyle.customButton, props.className,)}>
      {props.children}
    </button>
    // </div>
  );
};

const CustomDialog = (props) => {
  const dialogStyle = {
    backgroundImage: `url(${props.dialogContent.backgroundImage || baseBackgroundImg})`,
  };
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setShowText(false)
    const tt = setTimeout(() => {
      setShowText(true)
    }, 1000)

    return () => {
      clearTimeout(tt)
    }
  }, [props.open])

  const fakeChoiceStyle = props.dialogContent.style || {};

  const transition = {
    TransitionComponent: TransitionFade
  };

  return (
    <Dialog
      PaperProps={{ className: clsx('full-screen-dialog', props.dialogContent.addClassName ? props.dialogContent.addClassName : ''), style: dialogStyle }}
      {...transition}
      fullScreen
      open={props.open}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClick={props.close}
    >
      <DialogContent >
        <div style={{ height: '100%' }} className="flex flex-row justify-content-center align-items-center">
          <div onClick={(e) => { e.stopPropagation(); }} className={`fake-choice text-container ${showText ? 'show' : ''}`} style={fakeChoiceStyle}>
            <div className="close-button-container">
              <button onClick={props.close}>
                <img className="close-icon" src={closeBlack} alt='' />
              </button>
            </div>
            <div className={`text-container`}>
              <div className="text" dangerouslySetInnerHTML={{ __html: props.dialogContent.text }}></div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
