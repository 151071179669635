import { useContext, useEffect, useState } from 'react';

import PhylactereAlone from 'assets/img/phylactere.png';

import { LangContext } from 'langProvider';
import './style.css';

import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { CSSTransition } from 'react-transition-group';

import { useHistory } from 'react-router-dom';
import * as routes from 'routes';

import sifaBackgroundChoice from 'assets/img/journey/sifa/silhouette_choice.png';
import senaitBackgroundChoice from 'assets/img/journey/senait/Senait_portrait-min.png';
import hassanBackgroundChoice from 'assets/img/journey/hassan/portrait.png';

import homeImg from 'assets/img/home-icon.png';
// import homeHoverImg from 'assets/img/home-icon-hoover.png';

import overlay from 'assets/img/other_stories_overlay-min.png';

const allowedName = ['hassan', 'sifa', 'senait', 'senait_junior'];
const backgroundImages = {
  senait: senaitBackgroundChoice,
  sifa: sifaBackgroundChoice,
  hassan: hassanBackgroundChoice,
  senait_junior: senaitBackgroundChoice,
};

export const OtherStories = (props) => {
  const { lang } = useContext(LangContext);
  const history = useHistory();
  const locationState = history.location.state;
  const pageTransitionContext = useContext(PageTransitionContext);

  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    const locationState = history.location.state;
    if (!locationState || !locationState.name || allowedName.indexOf(locationState.name) < 0) {
      history.replace(routes.HOMEPAGE);
    }
  }, [history]);

  useEffect(() => {
    const tt = setTimeout(() => {
      setShowActions(true);
    }, 2000);
    return () => {
      clearTimeout(tt);
    };
  }, []);

  /* Styles */
  const sequenceContainer = {
    backgroundColor: 'white',
    color: 'black',
  };
  const fixedImageBackgroundStyle = {
    backgroundImage: locationState ? `url(${backgroundImages[locationState.name || '']})` : 'none',
    backgroundSize: '35% auto',
    backgroundPosition: '95% center',
  };
  const contentStyle = {
    width: '50%',
    maxWidth: '800px',
    marginLeft: '10%',
  };
  const contentTextStyle = {
    textTransform: 'none',
    textAlign: 'center',
    color: 'white',
    backgroundImage: `url(${PhylactereAlone})`,
  };
  const buttonStyle = { marginLeft: 'auto', marginRight: 'auto', marginTop: 20, padding: '30px!important', minHeight: 100, maxWidth: 200, color: 'white' };
  const thxMessage = {
    fr: "Merci...<br/> de t'être interessé<br/>à mon histoire !",
    nl: 'Bedankt<br/>voor je interesse<br/>in mijn verhaal',
  };

  const title = {
    fr: 'Veux-tu en déc<span class="background-underlined large">ouvrir d’autres ?</span>',
    nl: 'Wil je er <span class="background-underlined large">nog andere ontde</span>kken?',
  };

  const goNext = (route, state = {}) => {
    const nextPageTransition = getNextTransition();

    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(route, state);
    });
  };
  const goToMap = () => {
    goNext(routes.CHOOSE);
  };
  const goToHome = () => {
    const reviewSubmitted = Boolean(localStorage.getItem('reviewed')) || false;

    if (reviewSubmitted) goNext(routes.HOMEPAGE);
    else goNext(routes.REVIEW, { name: locationState.name });
  };
  const goToHomeForced = () => {
    setShowActions(false)
    goNext(routes.HOMEPAGE);
  };

  const go_direct_home = ["senait_junior"].indexOf(locationState.name) >= 0;

  return (
    <main className="main-otherStories fixed-height fixed-width ">
      {/* flex flex-column justify-content-center align-items-center */}
      <div id="other-choice" className="background-cover flex flex-column " style={sequenceContainer}>
        <div className="overlay">
          <img src={overlay} alt="" />
        </div>
        <div className="fixed-background background-cover" style={fixedImageBackgroundStyle}></div>
        {lang && !go_direct_home && (
          <>
            {/* justify-content-space-between */}
            <div className="content flex flex-column align-items-center " style={contentStyle}>
              {/* <CSSTransition in={!showConfirmation} timeout={0} classNames="question" unmountOnExit> */}
              <div className="content-text" dangerouslySetInnerHTML={{ __html: thxMessage[lang] }} style={contentTextStyle}></div>
              <CSSTransition in={showActions} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                <div className="actions-container">
                  <p className="title" dangerouslySetInnerHTML={{ __html: title[lang] }}></p>
                  <div className="actions flex flex-row justify-content-center flex-wrap">
                    <CustomButton onClick={goToMap} customstyle={buttonStyle} className="action-button bloc-noir">
                      <div>
                        {lang === 'fr' && 'Oui'}
                        {lang === 'nl' && 'Ja'}
                      </div>
                    </CustomButton>
                    <CustomButton onClick={goToHome} customstyle={buttonStyle} className="action-button bloc-noir">
                      <div>
                        {lang === 'fr' && 'Non'}
                        {lang === 'nl' && 'Nee'}
                      </div>
                    </CustomButton>
                  </div>
                </div>
              </CSSTransition>
            </div>
          </>
        )}

        {lang && go_direct_home && (
          <>
            {/* justify-content-space-between */}
            <div className="content flex flex-column align-items-center " style={contentStyle}>
              {/* <CSSTransition in={!showConfirmation} timeout={0} classNames="question" unmountOnExit> */}
              <div className="content-text" dangerouslySetInnerHTML={{ __html: thxMessage[lang] }} style={contentTextStyle}></div>
              <CSSTransition in={showActions} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                <div className="actions-container">
                  <div className="actions flex flex-row justify-content-center flex-wrap">
                    <CustomButton onClick={goToHomeForced} customstyle={{ ...buttonStyle, maxWidth: "none", width: "auto!important" }} className="action-button bloc-noir">
                      <img src={homeImg} alt="" style={{ display: "inline-block", verticalAlign: "middle" }} /> HOME
                    </CustomButton>
                  </div>
                </div>
              </CSSTransition>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

const CustomButton = (props) => {
  const customStyle = makeStyles((theme) => ({
    customButton: props.customstyle || {},
  }))();

  return (
    // <div className={}>
    <button disabled={props.isDisabled} onClick={props.onClick} className={clsx(customStyle.customButton, props.className)}>
      {props.children}
    </button>
    // </div>
  );
};
