import './style.css';
import { useHistory } from 'react-router-dom';
import { useContext, useMemo, useRef, useEffect, useState, useCallback } from 'react';

import * as routes from 'routes';

import useWindowDimensions from 'useWindowDimensions';

import { AudioContext } from 'audioProvider';
import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';
import { LangContext } from 'langProvider';

import scrollBarBackground from 'assets/img/scrollBar-min.png';

var Timer = function (callback, delay) {
  var timerId = null;
  var start = null;
  var remaining = delay;

  this.pause = function () {
    window.clearTimeout(timerId);
    remaining -= Date.now() - start;
  };

  this.resume = function () {
    if (remaining <= 0) return;

    start = Date.now();
    window.clearTimeout(timerId);
    timerId = window.setTimeout(() => {
      callback();
      remaining = 0;
    }, remaining);
  };

  this.clear = function () {
    window.clearTimeout(timerId);
  };

  this.resume();
};

const minHandlePosition = 0;
const maxHandlePosition = 100;

export const Epilogue = (props) => {
  const { content } = props;
  const history = useHistory();
  const pageTransitionContext = useContext(PageTransitionContext);
  const { lang } = useContext(LangContext);

  const { width, height } = useWindowDimensions();

  const { audio, setSource } = useContext(AudioContext);

  /* Epilogue counter */
  useEffect(() => {
    const epilogueCounter = Number(localStorage.getItem('epilogue')) || 0;
    localStorage.setItem('epilogue', epilogueCounter + 1);
  }, []);

  /* Styles */
  const epilogueContainer = {
    backgroundImage: `url(${content.background.src})`,
  };

  const goNextStep = useMemo(() => {
    return () => {
      const nextStepIndex = props.allSteps.findIndex((step) => step.id === props.content.nextStepId);

      let nextPageLocation, nextPageTransition;
      let state = {}

      if (nextStepIndex < 0) {
        // nextPageLocation = routes.HOMEPAGE;
        // nextPageTransition = getNextTransition();

        nextPageLocation = routes.OTHERSTORIES;
        nextPageTransition = getNextTransition();
        state = {
          name: props.name
        };

      } else {
        nextPageLocation = `${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`;
        nextPageTransition = getNextTransition(props.allSteps[nextStepIndex].type, 'epilogue');
      }

      pageTransitionContext.handleSetAnimation(nextPageTransition).then((value) => {
        history.push(nextPageLocation, state);
      });

      // if (nextStepIndex < 0) history.push(routes.HOMEPAGE);
      // else history.push(`${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`);
    };
  }, [history, props.allSteps, props.content.nextStepId, props.name, pageTransitionContext]);

  const sequence = useMemo(() => {
    return props.content.sequence || [];
  }, [props.content.sequence]);

  const sound = useMemo(() => {
    return (props.content.sound && props.content.sound) || null;
  }, [props.content.sound]);

  const soundDelay = useMemo(() => {
    return (props.content.sound_delay && props.content.sound_delay) || 0;
  }, [props.content.sound_delay]);

  const currentTimeouts = useRef([]);

  const [epilogueText, setEpilogueText] = useState({
    title: null,
    text: null,
    style: {},
  });

  const [foregroundImage, setForegroundImage] = useState({
    src: (content.foreground && content.foreground.src) || null,
    style: (content.foreground && content.foreground.style) || {},
  });

  /* TEXT */
  useEffect(() => {
    let delay = 0;

    const timeoutToCancel = [];
    sequence.forEach((seq) => {
      delay += seq.delay;

      const textTimeout = new Timer(() => {
        setEpilogueText({
          title: seq.title || null,
          text: seq.text || '',
          style: seq.textStyle || {},
        });
      }, delay);
      timeoutToCancel.push(textTimeout);
    });

    if (sound) {
      const audioTimeout = new Timer(() => {
        setSource(sound);
        audio.play();
      }, soundDelay);
      timeoutToCancel.push(audioTimeout);
    }

    timeoutToCancel.forEach((ttc) => {
      currentTimeouts.current.push(ttc);
    });

    return () => {
      setEpilogueText({ title: null, text: null, style: {} });

      if (sound) {
        if (audio.src.includes(sound)) {
          if (!audio.paused) audio.pause();
          setSource('');
        }
      }

      timeoutToCancel.forEach((TTC) => TTC.clear());
    };
  }, [sequence, goNextStep, sound, soundDelay, audio, setSource, props.lang]);

  /* FOREGROUND */
  /* FOREGROUND EFFECT */
  useEffect(() => {
    let delay = 0;

    if (!content.foreground || !content.foreground.animations) return;

    const timeoutToCancel = [];
    content.foreground.animations.forEach((seq) => {
      delay += seq.delay;

      const foregroundTimeout = new Timer(() => {
        setForegroundImage((curBack) => ({
          src: seq.src || curBack.src,
          style: {
            ...(content.foreground.style || {}),
            ...(seq.style || {}),
          },
        }));
      }, delay);
      timeoutToCancel.push(foregroundTimeout);
    });

    timeoutToCancel.forEach((ttc) => {
      currentTimeouts.current.push(ttc);
    });

    return () => {
      timeoutToCancel.forEach((TTC) => TTC.clear());
    };
  }, [content.foreground]);

  /*  pausing on screen shift */
  useEffect(() => {
    if (width < height) {
      if (!audio.paused) {
        audio.pause();
      }
      if (currentTimeouts.current) {
        currentTimeouts.current.forEach((CT) => CT.pause());
      }
    } else {
      if (audio.src && audio.paused && audio.played.length) {
        audio.play();
      }
      if (currentTimeouts.current) {
        currentTimeouts.current.forEach((CT) => CT.resume());
      }
    }
  }, [width, height, sound, audio]);

  /* Custom Scroll bar */
  const [customScrollBar, showCustomScrollBar] = useState(false);
  const scrollHandleContainer = useRef();
  const [scrollHandlePosition, setScrollHandlePosition] = useState(minHandlePosition);
  const [rangeHeight, setRangeHeight] = useState(0);

  const textContent = useRef();
  const textScroller = useRef();
  const textContainer = useRef();

  const computeScrollHandlePosition = useCallback(() => {
    // const containerHeight = textContainer.current.clientHeight;
    const textHeight = textContent.current.clientHeight;
    const scrollHeight = textScroller.current.clientHeight;
    const textPosition = textScroller.current.scrollTop;

    // const pad = Math.min(Math.max(50, 0.06*height), 80);

    // const scrollLength = textHeight - containerHeight + 2*pad;

    const scrollLength = textHeight - scrollHeight;

    const handleRatio = textPosition / scrollLength;
    const handlePosition = minHandlePosition + handleRatio * (maxHandlePosition - minHandlePosition);

    setScrollHandlePosition(handlePosition || 0);
  }, []);

  const initScrollPosition = useCallback(() => {

    if (!textContent.current) return;
    const textHeight = textContent.current.clientHeight;
    const containerHeight = textScroller.current.clientHeight;

    // setTextContainerStyle((prevStyle) => ({ ...prevStyle, height: '100%' }));

    if (textHeight <= containerHeight) {
      showCustomScrollBar(false);
    } else {
      showCustomScrollBar(true);

      setRangeHeight(scrollHandleContainer.current.clientHeight);
      computeScrollHandlePosition();
    }
  }, [computeScrollHandlePosition]);

  useEffect(() => {
    if (textContent.current) {
      initScrollPosition();
      // setRangeHeight(scrollHandleContainer.current.clientHeight)
    }
  }, [width, height, initScrollPosition, epilogueText.title, epilogueText.text]);

  const handleChangeScroll = (v) => {
    // const textHeight = textContent.current.clientHeight;
    // const containerHeight = textContainer.current.clientHeight;

    const textHeight = textContent.current.clientHeight;
    const scrollHeight = textScroller.current.clientHeight;

    // const scrollLength = textHeight - containerHeight + 80;

    const scrollLength = textHeight - scrollHeight;

    const handleRatio = (scrollLength * v.target.value) / 100;

    textScroller.current.scrollTop = handleRatio;
  };

  return (
    <div id="epilogue" className={`background-cover flex flex-column justify-content-center ${content.epilogueContainerClass || ''}`} style={epilogueContainer}>
      {props.lang && (
        <>
          <div className={`background-image flex flex-column justify-content-center}`}>
            <img src={foregroundImage.src} alt="" style={foregroundImage.style || {}} />
            {/*  />
            <img className={`img-2 ${backgroundImage.showImage === 'img-2' ? 'show' : ''}`} src={backgroundImage['img-2']} alt="" style={backgroundImage['img-2-style'] || {}} /> */}
          </div>

          <div ref={textContainer} className={`text-container flex flex-column ${(epilogueText.text || epilogueText.title) ? 'show' : ''}`} style={epilogueText.style || {}}>
            <div ref={textScroller} className="scroller" onScroll={computeScrollHandlePosition}>
              <div ref={textContent}>
                {epilogueText.title && <div className="title" dangerouslySetInnerHTML={{ __html: epilogueText.title }}></div>}
                <div className="text" dangerouslySetInnerHTML={{ __html: epilogueText.text }}></div>
              </div>
            </div>

            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={`scroll-bar ${customScrollBar ? 'visible' : ''}`}
              style={{ backgroundImage: `url(${scrollBarBackground})` }}
            >
              <div ref={scrollHandleContainer} className="scroll-handle-container">
                <input
                  className="range-handle"
                  type="range"
                  orient="horizontal"
                  value={scrollHandlePosition}
                  onChange={handleChangeScroll}
                  min={minHandlePosition}
                  max={maxHandlePosition}
                  style={{ width: rangeHeight }}
                />
              </div>
            </div>
          </div>
          {(epilogueText.text || epilogueText.title) && (
            <div className="button-container" style={epilogueText.style || {}}>
              <button className="button-terminer" onClick={goNextStep}>
                {lang === 'fr' && 'Terminer'}{lang === 'nl' && 'Eindigen'}&nbsp;<span>{'>'}</span>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
