import { useEffect, useMemo, useContext } from 'react';

import { LoadAssetsContext } from 'loadAssetsProvider';
import { LangContext } from 'langProvider';

import './style.css';

import * as routes from 'routes';
import { stories } from 'assets/stories';
import { useHistory, useParams } from 'react-router-dom';

import backgroundImg from 'assets/img/home-background-min.jpg';
// import loadingSpinner from 'assets/img/loading.svg';

import { Sequence } from './sequence';
import { Info } from './info';
import { Choice } from './choice';
import { JourneyMap } from './map';
import { Epilogue } from './epilogue';

import loadingDot1 from 'assets/img/loading_dot1.png';
import loadingDot2 from 'assets/img/loading_dot2.png';
import loadingDot3 from 'assets/img/loading_dot3.png';

import shoesCongo from 'assets/maps/shoes_congo.svg';
import shoesErythree from 'assets/maps/shoes_erythree.svg';
import shoesSyrie from 'assets/maps/shoes_syrie.svg';

export const Journey = () => {
  const history = useHistory();
  const { name, step } = useParams();
  const { loaded, changeJourney } = useContext(LoadAssetsContext);
  const { lang } = useContext(LangContext);

  useEffect(() => {
    if (!lang || !(name in stories[lang])) {
      history.replace(routes.HOMEPAGE);
    }
  }, [name, history, lang]);

  const journey = useMemo(() => ((lang in stories) && stories[lang][name]) || {}, [name, lang]);

  useEffect(() => {
    // console.log(name, lang)
    if (!journey.steps || step > journey.steps.length) {
      history.replace(routes.HOMEPAGE);
    } else {
      if (lang) {
        changeJourney(name, lang);
      }
    }
  }, [name, lang, changeJourney, history, journey, step]); // [step, journey, history, name, changeJourney, lang]);

  //   useEffect(() => {

  //   }, [name, changeJourney]);

  useEffect(() => {
    if (!journey || !journey.steps) {
      history.replace(routes.HOMEPAGE);
    }
  }, [journey, history]);

  useEffect(() => {
    if (!Number(step)) {
      history.replace(routes.HOMEPAGE);
    }
  }, [step, history]);

  if (!journey || !journey.steps || !Number(step) || Number(step) > journey.steps.length) {
    return <></>
  }

  const stepContent = journey.steps[Number(step) - 1];

  const nextContentProps = {
    content: stepContent,
    allSteps: journey.steps,
    lang: lang,
    name: name,
    step: step,
    skipAutoNextStep: journey.skipAutoNextStep ?? false,
    soundsInLoop: journey.soundsInLoop ?? false,
  };

  return (
    <main className="main-journey fixed-height fixed-width">
      <LoadingScreen loading={!loaded} name={name} />
      {loaded && stepContent.type === 'sequence' && <Sequence {...nextContentProps} />}
      {loaded && stepContent.type === 'info' && <Info {...nextContentProps} />}
      {loaded && stepContent.type === 'choice' && <Choice {...nextContentProps} />}
      {loaded && stepContent.type === 'map' && <JourneyMap {...nextContentProps} />}
      {loaded && stepContent.type === 'epilogue' && <Epilogue {...nextContentProps} />}
    </main>
  );
};

const LoadingScreen = (props) => {
  let shoes_image;
  switch (props.name) {
    case 'senait':
      shoes_image = shoesErythree;
      break;
    case 'sifa':
      shoes_image = shoesCongo;
      break;
    case 'hassan':
      shoes_image = shoesSyrie;
      break;
    default:
      break;
  }
  return (
    <div
      className={'loading-container fixed-height fixed-width background-cover flex flex-column justify-content-center align-items-center' + (props.loading ? ' loading' : '')}
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      {props.loading && (
        <>
          <img className="loading-spinner" src={shoes_image} alt="" />
          <div className="loading-dots">
            <img className="loading-dot" src={loadingDot1} alt="" />
            <img className="loading-dot" src={loadingDot2} alt="" />
            <img className="loading-dot" src={loadingDot3} alt="" />
          </div>
        </>
      )}
    </div>
  );
};
