import './style.css';

import homeImg from 'assets/img/home-icon.png';
import homeHoverImg from 'assets/img/home-icon-hoover.png';

import { useHistory } from 'react-router-dom';
import * as routes from 'routes';
import { useContext, useState, useMemo, forwardRef, useRef, useEffect, useCallback } from 'react';
import useWindowDimensions from 'useWindowDimensions';

import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';

// import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import crossWhite from 'assets/img/cross-white.svg';
import squareWhiteBackground from 'assets/img/info-white-background.png';
import scrollBarBackground from 'assets/img/scrollBar-min.png';
// import scrollBarHandle from 'assets/img/scollBarHandle-min.png';

import baseBackgroundImg from 'assets/img/home-background-min.jpg';

import { CSSTransition } from 'react-transition-group';

// import videojs from 'video.js';
// import 'video.js/dist/video-js.css';
// import 'videojs-youtube';

const TransitionSlideUp = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={{ enter: 750, exit: 750 }} />;
});
const TransitionFade = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={{ enter: 750, exit: 750 }} />;
});

export const Info = (props) => {
  const { content } = props;
  const history = useHistory();
  const goToChoiceRef = useRef();

  const pageTransitionContext = useContext(PageTransitionContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});

  const [showActions, setShowActions] = useState(false);

  const [infoRead, setInfoRead] = useState(false);

  useEffect(() => {
    const tt = setTimeout(() => {
      setShowActions(true);
    }, 2000);
    return () => {
      clearTimeout(tt);
    };
  }, []);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  /* Styles */
  const sequenceContainer = {
    // backgroundImage: `url(${content.background})`,
    ...(content.containerStyle || {}),
  };
  const fixedImageBackgroundStyle = {
    backgroundImage: `url(${content.fixedImageBackground || ''})`,
    ...(content.fixedImageBackgroundStyle || {}),
  };
  const contentStyle = {
    ...(content.contentStyle || {}),
  };

  const goNextStep = useMemo(() => {
    return () => {
      const nextStepIndex = props.allSteps.findIndex((step) => step.id === props.content.nextStepId);

      let nextPageLocation, nextPageTransition;

      if (nextStepIndex < 0) {
        nextPageLocation = routes.HOMEPAGE;
        nextPageTransition = getNextTransition();
      } else {
        nextPageLocation = `${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`;
        nextPageTransition = getNextTransition(props.allSteps[nextStepIndex].type, 'info');
      }

      pageTransitionContext.handleSetAnimation(nextPageTransition).then((value) => {
        history.push(nextPageLocation);
      });

      // if (nextStepIndex < 0) history.push(routes.HOMEPAGE);
      // else history.push(`${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`);
    };
  }, [history, props.allSteps, props.content.nextStepId, props.name, pageTransitionContext]);

  const goBackHome = () => {
    const nextPageTransition = getNextTransition();

    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(routes.HOMEPAGE);
    });
  };

  // const sequence = useMemo(() => {
  //   return (props.content.sequence && props.content.sequence[props.lang]) || [];
  // }, [props.content.sequence, props.lang]);

  return (
    <div id="info" className="background-cover flex flex-column " style={sequenceContainer}>
      {content.fixedImageBackground && <div className="fixed-background background-cover" style={fixedImageBackgroundStyle}></div>}
      {props.lang && (
        <>
          <div className="control-container flex">
            <button className="go-back-home-button" onClick={goBackHome}>
              <span className="info">{'<'}&nbsp;Home </span> <img src={content.homeIcon || homeImg} alt="" /> <img className="on-hover" src={content.homeHoverIcon || homeHoverImg} alt="" />
            </button>
          </div>

          <div className="content flex flex-column " style={contentStyle}>
            <div className="content-text" dangerouslySetInnerHTML={{ __html: content.contentText }}></div>

            <CSSTransition in={showActions} timeout={1000} classNames="actionsAnimation" unmountOnExit>
              <>
                <div className="actions-container">
                  {content.actionText && <div className="action-text" dangerouslySetInnerHTML={{ __html: content.actionText }}></div>}
                  <div className="actions flex flex-wrap justify-content-center align-items-start">
                    {content.actions &&
                      content.actions.map((act, ind) => {
                        if (act.type === 'button') {
                          let clickAction = () => {
                            setInfoRead(true);
                            console.warn('undefined action');
                          };
                          if (act.action === 'openDialog') {
                            clickAction = () => {
                              setInfoRead(true);
                              setDialogContent(act.dialogContent || {});
                              handleDialogOpen();

                              setTimeout(() => {
                                goToChoiceRef.current.scrollIntoView({ behavior: 'smooth' });
                              }, 500);
                            };
                          }
                          return (
                            <CustomButton key={act.key || ind} onClick={clickAction} customstyle={act.buttonStyle || {}} className="action-button">
                              <div className="icon">
                                {act.icon && <img src={act.icon} alt="" />}
                                {act.iconHover && <img className="on-hover" src={act.iconHover} alt="" />}
                              </div>
                              <div>{act.text}</div>
                            </CustomButton>
                          );
                        }
                        return <></>;
                      })}
                  </div>
                </div>
                <div ref={goToChoiceRef} className="action-next">
                  <CustomButton onClick={goNextStep} disabled={!infoRead} className="go-next-button" customstyle={content.nextButtonStyle || {}}>
                    <div>{content.nextButtonText}</div>
                  </CustomButton>
                </div>
              </>
            </CSSTransition>
          </div>
        </>
      )}

      <CustomDialog lang={props.lang} open={dialogOpen} dialogContent={dialogContent} close={handleDialogClose}></CustomDialog>
    </div>
  );
};

const CustomButton = (props) => {
  const customStyle = makeStyles((theme) => ({
    customButton: props.customstyle || {},
  }))();

  return (
    <button onClick={props.onClick} disabled={props.disabled || false} className={clsx(props.className, customStyle.customButton)}>
      {props.children}
    </button>
  );
};

const minHandlePosition = 0;
const maxHandlePosition = 100;

const CustomDialog = (props) => {
  const textContainer = useRef();
  const textContent = useRef();
  const textScroller = useRef();

  const [customScrollBar, showCustomScrollBar] = useState(false);
  const [scrollHandlePosition, setScrollHandlePosition] = useState(minHandlePosition);

  const [rangeHeight, setRangeHeight] = useState(0);
  const scrollHandleContainer = useRef();

  const { width, height } = useWindowDimensions();

  const [flexContainerStyle, setFlexContainerStyle] = useState({
    height: '100%',
    opacity: 0,
    transition: 'opacity 500ms linear',
  });

  const [textContainerStyle, setTextContainerStyle] = useState({
    backgroundImage: `url(${squareWhiteBackground})`,
    height: '100%',
  });

  useEffect(() => {
    setFlexContainerStyle((prevStyle) => ({ ...prevStyle, opacity: 0 }));

    const tt = setTimeout(() => {
      setFlexContainerStyle((prevStyle) => ({ ...prevStyle, opacity: 1 }));
    }, 500);

    return () => {
      clearTimeout(tt);
    };
  }, [props.open]);

  const initScrollPosition = useCallback(() => {
    if (!textContent.current) return;
    const textHeight = textContent.current.clientHeight;
    const containerHeight = textScroller.current.clientHeight;

    // console.log(textHeight, containerHeight);
    // setTextContainerStyle((prevStyle) => ({ ...prevStyle, height: '100%' }));

    if (textHeight <= containerHeight) {
      showCustomScrollBar(false);
      setTextContainerStyle((prevStyle) => ({ ...prevStyle, height: 'auto' }));
    } else {
      showCustomScrollBar(true);

      setRangeHeight(scrollHandleContainer.current.clientHeight);
      computeScrollHandlePosition();
    }
  }, []);

  useEffect(() => {
    if (textContent.current) {
      initScrollPosition();
      // setRangeHeight(scrollHandleContainer.current.clientHeight)
    }
  }, [width, height, initScrollPosition]);

  const computeScrollHandlePosition = () => {
    const containerHeight = textContainer.current.clientHeight;
    const textHeight = textContent.current.clientHeight;
    const textPosition = textScroller.current.scrollTop;

    const scrollLength = textHeight - containerHeight + 80;

    const handleRatio = textPosition / scrollLength;
    const handlePosition = minHandlePosition + handleRatio * (maxHandlePosition - minHandlePosition);

    setScrollHandlePosition(handlePosition);
  };

  const dialogStyle = {
    backgroundImage: props.dialogContent.backgroundImage ? `url(${props.dialogContent.backgroundImage})` : 'none',
  };

  if (props.dialogContent.type === 'video') {
    dialogStyle.backgroundImage = `url(${props.dialogContent.backgroundImage || baseBackgroundImg})`;
  }

  const transition = {
    TransitionComponent: TransitionFade,
  };
  if (props.dialogContent.transition === 'slideUp') transition.TransitionComponent = TransitionSlideUp;

  // const videoJsOptions = {
  //   autoplay: false,
  //   controls: true,
  // };
  // if (props.dialogContent.type==="video"){
  //   videoJsOptions.sources = [{
  //     src: 'https://vimeo.com/25323516',//props.dialogContent.videoSrc,
  //     // type: props.dialogContent.videoSrcType,
  //   }]
  // }

  const handleChangeScroll = (v) => {
    const textHeight = textContent.current.clientHeight;
    const containerHeight = textContainer.current.clientHeight;

    const scrollLength = textHeight - containerHeight + 80;

    const handleRatio = (scrollLength * v.target.value) / 100;

    textScroller.current.scrollTop = handleRatio;
  };

  return (
    <Dialog
      PaperProps={{ className: clsx('full-screen-dialog', props.dialogContent.addClassName ? props.dialogContent.addClassName : ''), style: dialogStyle }}
      {...transition}
      fullScreen
      open={props.open}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onEntered={initScrollPosition}
      // onPointerUp={handlePointerUp} onPointerMove={handleMovePointer}
      onClick={props.close}
      className={clsx({ imageDialog: props.dialogContent.type === 'image' })}
    >
      <DialogActions className="info-dialog-actions">
        <button className="close-dialog-button" onClick={props.close} color="primary">
          <img className="close-icon" src={crossWhite} alt="" />
        </button>
      </DialogActions>

      {/* <DialogTitle id="alert-dialog-title" className="dialog-title">
        <button onClick={props.close}>
          <img class="" src={crossWhite} alt="" />
        </button>
      </DialogTitle> */}

      <DialogContent className="flex flex-column justify-content-center">
        {props.dialogContent.type === 'text' && (
          // <DialogContentText id="alert-dialog-description" className="info-dialog-text">
          <div className="info-dialog-text">
            <div style={flexContainerStyle} className="flex flex-row justify-content-center align-items-center">
              <div
                ref={textContainer}
                className="text-container"
                style={textContainerStyle}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div ref={textScroller} className="text-content" onScroll={computeScrollHandlePosition}>
                  <div ref={textContent}>
                    <div className="title" dangerouslySetInnerHTML={{ __html: props.dialogContent.title }}></div>
                    <div className="text" dangerouslySetInnerHTML={{ __html: props.dialogContent.text }}></div>
                  </div>
                </div>
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={`scroll-bar ${customScrollBar ? 'visible' : ''}`}
                style={{ backgroundImage: `url(${scrollBarBackground})` }}
              >
                <div ref={scrollHandleContainer} className="scroll-handle-container">
                  <input
                    className="range-handle"
                    type="range"
                    orient="horizontal"
                    value={scrollHandlePosition}
                    onChange={handleChangeScroll}
                    min={minHandlePosition}
                    max={maxHandlePosition}
                    style={{ width: rangeHeight }}
                  />
                </div>
              </div>
            </div>
          </div>
          // </DialogContentText>
        )}
        {props.dialogContent.type === 'video' && (
          <div style={{ height: '100%' }} className="flex flex-row justify-content-center">
            {/* <VideoPlayer {...videoJsOptions} /> */}
            <VideoPlayer videoIframe={props.dialogContent.videoIframe} videoSrc={props.dialogContent.videoSrc} />
          </div>
        )}
        {props.dialogContent.type === 'image' && (
          <div
            className="info-dialog-image flex flex-column align-items-center justify-content-center"
            style={{ height: '100%', transition: 'opacity 500ms linear', opacity: flexContainerStyle.opacity }}
          >
            <img style={props.dialogContent.imageStyle || {}} src={props.dialogContent.imageSrc} alt="" />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const iframeVideoStyle = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    width: '90%',
    maxWidth: 1200,
    // height: '100%',
    height: '70Vh',
    maxHeight: 800,
    padding: 0,
    position: 'relative',
    '& iframe': {
      width: '100%',
      height: '100%',
      // maxHeight: '70vh',
    },
  },
}));
const VideoPlayer = (props) => {
  const classes = iframeVideoStyle();
  // const videoNode = useRef();
  // const videoPlayer = useRef();

  // useEffect(() => {
  //   videoPlayer.current = videojs(videoNode.current, props, function onPlayerReady() {
  //     console.log('onPlayerReady', videoPlayer);
  //   });

  //   return () => {
  //     if (videoPlayer.current) {
  //       videoPlayer.current.dispose();
  //     }
  //   };
  // });

  // data-setup='{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "https://www.youtube.com/watch?v=xjS6SftYQaQ"}] }'

  const hytPlayerWrap = useRef();

  useEffect(() => {
    // if (window.hideYTActivated) return;
    let YT = window.YT;

    if (typeof YT === 'undefined') {
      console.log('adding YT API script');
      let tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    let playerFrame = hytPlayerWrap.current.querySelector('iframe');
    let onPlayerStateChange = (event) => {
      // console.log('event shot', event);
      if (event.data === YT.PlayerState.ENDED) {
        hytPlayerWrap.current.classList.add('ended');
      } else if (event.data === YT.PlayerState.PAUSED) {
        hytPlayerWrap.current.classList.add('paused');
      } else if (event.data === YT.PlayerState.PLAYING) {
        hytPlayerWrap.current.classList.remove('ended');
        hytPlayerWrap.current.classList.remove('paused');
      }
    };

    let player;
    const startTracker = () => {
      // console.log('strat tracker');
      player = new YT.Player(playerFrame, { events: { onStateChange: onPlayerStateChange } });
    };

    hytPlayerWrap.current.addEventListener('click', function (event) {
      event.preventDefault();
      event.stopPropagation();

      // console.log('player click');
      let playerState = player.getPlayerState();
      if (playerState === YT.PlayerState.ENDED) {
        player.seekTo(0);
      } else if (playerState === YT.PlayerState.PAUSED) {
        player.playVideo();
      }
    });

    if (typeof YT !== 'undefined') {
      startTracker();
    } else {
      window.onYouTubeIframeAPIReady = function () {
        YT = window.YT;
        startTracker();
      };
    }

    // window.hideYTActivated = true;
  }, []);

  return (
    <div className="hytPlayerWrapOuter flex flex-column justify-content-center align-items-center">
      <div
        ref={hytPlayerWrap}
        className={clsx(classes.container, 'hytPlayerWrap flex flex-column justify-content-center align-items-center')}
        dangerouslySetInnerHTML={{ __html: props.videoIframe }}
      ></div>
    </div>
    // <div>
    //   <div data-vjs-player>
    //     <video ref={videoNode} className="video-js" ></video>
    //   </div>
    // </div>
  );
};
