import { createContext, useEffect, useState } from 'react';

export const JuniorContext = createContext(null);
export const JuniorProvider = (props) => {
  const [junior, setJunior] = useState(false);

  const changeJunior = (isJunior) => {
    const newValue = !!isJunior
    localStorage.setItem('junior', newValue);
    setJunior(newValue)

    if (newValue) document.body.classList.add("junior")
    else document.body.classList.remove("junior")
  };
  const getJunior = () => {
    const juniorState = JSON.parse(localStorage.getItem('junior')) || false;
    return juniorState;
  };
  useEffect(() => {
    const curJunior = getJunior();
    changeJunior(curJunior)
  }, []);

  return <JuniorContext.Provider value={{ junior, changeJunior }}>{props.children}</JuniorContext.Provider>;
};
