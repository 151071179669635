import { useState, useEffect } from 'react';

// let dimensionTimeout = null;
const dimensionTimeoutDuration = 250;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  // console.log(window.innerHeight, window.innerWidth)
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      // if (dimensionTimeout) {
      //   clearTimeout(dimensionTimeout);
      // }
      // dimensionTimeout = 
      setTimeout(() => {
        // console.log('setting windows dimension')
        setWindowDimensions(getWindowDimensions());
        // dimensionTimeout = null;
      }, dimensionTimeoutDuration);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
