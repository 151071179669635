import { createContext, useEffect, useState } from 'react';

const allowedLanguage = ['fr', 'nl'];

export const LangContext = createContext(null);
export const LangProvider = (props) => {
  const [langState, setLangState] = useState(null);

  const changeLang = (lang) => {
    document.documentElement.lang = lang;
    if (allowedLanguage.indexOf(lang) < 0) return;
    localStorage.setItem('lang', lang);
    setLangState(lang)
  };
  const getLang = () => {

    return localStorage.getItem('lang') || '';
  };
  useEffect(() => {
    const curLang = getLang();
    setLangState(curLang)
  }, []);

  return <LangContext.Provider value={{ lang: langState, changeLang }}>{props.children}</LangContext.Provider>;
};
