import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/flaticons/flaticon.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { LangProvider } from './langProvider';
import { JuniorProvider } from './juniorProvider';

import { AudioProvider } from './audioProvider';

ReactDOM.render(
  <React.StrictMode>
    <LangProvider>
      <JuniorProvider>
        <AudioProvider>
          <App />
        </AudioProvider>
      </JuniorProvider>
    </LangProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
