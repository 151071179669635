import { useContext, useEffect, useState } from 'react';

import { LangContext } from 'langProvider';
import './style.css';

import useWindowDimensions from 'useWindowDimensions';

import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';
import { FirebaseContext, FirebaseProvider } from 'myFirebase';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { CSSTransition } from 'react-transition-group';

import { useHistory } from 'react-router-dom';
import * as routes from 'routes';

import backgoundPurple from 'assets/img/backgound_purple.jpg';
import blocBackground from 'assets/img/block_blanc-eval.png';
import blocNoirTitleBackground from 'assets/img/cadre_noir-eval.png';

import arrow_eval_down from 'assets/img/arrow_eval_down.svg';
import arrow_eval_up from 'assets/img/arrow_eval_up.svg';

import point_1 from 'assets/img/1_point.png';
import point_2 from 'assets/img/2_point.png';
import point_3 from 'assets/img/3_point.png';
import point_4 from 'assets/img/4_point.png';
import point_5 from 'assets/img/5_point.png';

import caritasLogo from 'assets/img/Caritas_int_be_blanc_seul@2x.png';
import justicePaixLogo from 'assets/img/20180124_Justice_Paix_Logo_RVB-Positif@2x.png';
import switchLogo from 'assets/img/switch-logo.svg';
import CBD_logo_NL from 'assets/img/CBD_logo_NL_white@2x.png';
import CBD_logo_FR from 'assets/img/CBD_logo_FR_white@2x.png';

const allowedName = ['hassan', 'sifa', 'senait', 'homepage'];

export const Review = (props) => {
  const { lang } = useContext(LangContext);
  const history = useHistory();
  const locationState = history.location.state;
  const pageTransitionContext = useContext(PageTransitionContext);
  const firebaseContext = useContext(FirebaseContext);
  const { width, height } = useWindowDimensions();

  const [showItem, setShowItem] = useState({ item: 1 }); //1 }); // item: 0

  const minNbRows = 2;
  const [answers, setAnswer] = useState({ open: '', nbRows: minNbRows });

  useEffect(() => {
    const locationState = history.location.state;
    if (!locationState || !locationState.name || allowedName.indexOf(locationState.name) < 0) {
      history.replace(routes.HOMEPAGE);
    }
  }, [history]);

  /* Styles */
  const sequenceContainer = {
    backgroundColor: 'white',
    color: 'black',
    backgroundImage: `url(${backgoundPurple})`,
  };
  const contentContainer = {
    backgroundImage: `url(${blocBackground})`,
  };

  const buttonStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    padding: '30px!important',
    minHeight: 100,
    maxWidth: 200,
    color: 'white',
  };

  const titleMessage = {
    fr: 'Ton avis nous intéresse !',
    nl: 'We willen je mening horen!',
  };

  const questionMessage = {
    0: {
      fr: 'Veux-tu bien nous donner ton avis <span class="background-underlined large">en 3 clics</span>&thinsp;?',
      nl: 'Wil je je mening geven met slechts <span class="background-underlined large">3 klikken</span>?',
    },
    1: {
      fr: 'As-tu aimé ce <span class="background-underlined large">récit interactif</span> ?',
      nl: 'Vond je deze in<span class="background-underlined large">teractieve tocht</span> interessant? ',
    },
    2: {
      fr: 'Te sens-tu p<span class="background-underlined large">lus concerné&#xb7;e</span><br/>par les questions de migration,<br/>après cette expérience ?',
      nl: 'Voel je je na deze ervaring me<span class="background-underlined medium">er betrokken</span> bij thema’s rond migratie ?',
    },
    3: {
      fr: 'As-tu davantage <span class="background-underlined large">envie de t\'engager</span><br/>pour p<span class="background-underlined large">lus de solidarit</span>é et <span class="background-underlined medium">de justice</span><br/>dans le monde ?',
      nl: 'Heb je zin gekregen om je <span class="background-underlined large">te engageren</span> voor een meer <span class="background-underlined large">solidaire</span> en <span class="background-underlined large">rechtvaardige</span> wereld ?',
    },
    '3bis': {
      fr: 'Comment ferais-tu&thinsp;?',
      nl: 'Hoe zou jij dat doen?',
    },
    4: {
      fr: 'As-tu des idées à proposer <span class="background-underlined large">pour encourager</span> les gens à s’engager d’avantage?',
      nl: 'Heb je ideeën om mensen aan <span class="background-underlined large">te moedig</span>en om zich te engageren ?',
    },
    last: {
      fr: 'Merci!',
      nl: 'Dank u!',
    },
  };

  const placeHolder = {
    fr: "Par exemple : s'informer, ou en parler avec des proches, ou soutenir une personne réfugiée...",
    nl: 'Bijvoorbeeld : zich informeren over bepaalde thema’s, met vrienden en familie over deze thema’s praten, een vluchteling ondersteunen...',
  };

  const goNext = (route) => {
    const nextPageTransition = getNextTransition();

    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(route);
    });
  };

  const goToHome = () => {
    goNext(routes.HOMEPAGE);
  };
  const goToNextQ = () => {
    const nextItem = showItem.item + 1;

    setShowItem({
      item: null,
    });
    setTimeout(() => {
      setShowItem({
        item: nextItem,
      });
    }, 1100);
  };

  const setQuestionScore = (Qname, value, goNext = true) => {
    setAnswer((prevAnswer) => ({ ...prevAnswer, [Qname]: value }));

    if (goNext) {
      goToNextQ();
    }
  };

  const goToLast = () => {
    setTimeout(() => {
      setShowItem({
        item: 'last',
      });

      setTimeout(() => {
        goToHome();
      }, 5000);
      
    }, 1000);
  };

  const sendReview = () => {
    setShowItem((curItem) => ({
      item: null,
    }));

    // setTimeout(() => {
    //   goToLast();
    //   return;
    // }, 2000);
    // return;

    const not = ['nbRows']; // properties to delete from obj object
    const review = Object.assign({}, answers);
    for (let n of not) delete review[n];

    firebaseContext.app
      .setReview({
        ...review,
        lastStory: locationState.name,
        width,
        height,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
      })
      .then(() => {
        localStorage.setItem('reviewed', true);
        goToLast();
      })
      .catch(() => {
        goToLast();
      });
  };

  const limit = 500;
  const updateOpenQuestion = ({ target }) => {
    const { value } = target;
    if (value.length > limit) return;

    const nbRows = computeNbRows(value);
    setAnswer((prevAnwer) => ({ ...prevAnwer, open: value, nbRows: nbRows }));
  };

  const computeNbRows = (value) => {
    if (!value) return minNbRows;
    return Math.max(minNbRows, Math.min(5, value.split('\n').length));
  };
  return (
    <FirebaseProvider>
      <main id="evaluation" className="flex flex-column justify-content-center align-items-center">
        <div className="content-scroller">
          <div className="sequence-container background-cover flex flex-column justify-content-center " style={sequenceContainer}>
            {/* flex flex-column justify-content-center */}
            <div className={clsx('content-container flex flex-column align-items-center background-100p', { 'last-content': showItem.item === 'last' })} style={contentContainer}>
              <h1 className="title" style={{ backgroundImage: `url(${blocNoirTitleBackground})` }}>
                <div>{titleMessage[lang] || ''}</div>
              </h1>
              <CSSTransition in={showItem.item === 0} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                <div className="action-content flex flex-column align-items-center">
                  <div className="actions-container container flex flex-column">
                    <p className="title" dangerouslySetInnerHTML={{ __html: questionMessage[0][lang] }}></p>
                    <div className="actions flex flex-row justify-content-center flex-wrap">
                      <CustomButton onClick={goToNextQ} customstyle={buttonStyle} className="action-button bloc-noir">
                        <div>
                          {lang === 'fr' && 'Oui'}
                          {lang === 'nl' && 'Ja'}
                        </div>
                      </CustomButton>
                      <CustomButton onClick={goToHome} customstyle={buttonStyle} className="action-button bloc-noir">
                        <div>
                          {lang === 'fr' && 'Non'}
                          {lang === 'nl' && 'Nee'}
                        </div>
                      </CustomButton>
                    </div>
                    <div className="counter hidden">0 / 3</div>
                  </div>
                </div>
              </CSSTransition>

              <CSSTransition in={showItem.item === 1} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                <div className="action-content flex flex-column align-items-center">
                  <div className="actions-container flex flex-column">
                    <p className="title" dangerouslySetInnerHTML={{ __html: questionMessage[1][lang] }}></p>
                    <div className="actions flex flex-row justify-content-center flex-wrap">
                      <SelectScore
                        setScore={(i) => {
                          setQuestionScore('1.liked', i);
                        }}
                      />
                    </div>
                    <div className="counter">1 / 3</div>
                  </div>
                </div>
              </CSSTransition>

              <CSSTransition in={showItem.item === 2} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                <div className="action-content flex flex-column align-items-center">
                  <div className="actions-container flex flex-column">
                    <p className="title" dangerouslySetInnerHTML={{ __html: questionMessage[2][lang] }}></p>
                    <div className="actions flex flex-row justify-content-center flex-wrap">
                      <SelectScore
                        setScore={(i) => {
                          setQuestionScore('2.concerned', i);
                        }}
                      />
                    </div>
                    <div className="counter">2 / 3</div>
                  </div>
                </div>
              </CSSTransition>

              <CSSTransition in={showItem.item === 3} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                <div className="action-content flex flex-column align-items-center">
                  <div className="actions-container flex flex-column">
                    <p className="title" dangerouslySetInnerHTML={{ __html: questionMessage[3][lang] }}></p>
                    <div className="actions flex flex-row justify-content-center flex-wrap">
                      <SelectScore
                        setScore={(i) => {
                          setQuestionScore('3.engaged', i, false);
                        }}
                        selected={answers['3.engaged']}
                      />
                    </div>

                    <p className="title" dangerouslySetInnerHTML={{ __html: questionMessage['3bis'][lang] }}></p>
                    <div className="flex flex-row align-items-center">
                      <div className="actions flex flex-row justify-content-end flex-wrap flex-1">
                        <textarea className="open-text" onChange={updateOpenQuestion} value={answers.open} rows={answers.nbRows} placeholder={placeHolder[lang]}></textarea>
                        <div className="text-length">
                          {answers.open.length}/{limit}
                        </div>
                      </div>

                      <CustomButton isDisabled={!answers['3.engaged']} onClick={sendReview} className="submit-button">
                        {lang === 'fr' && 'Ok'}
                        {lang === 'nl' && 'Ok'}
                      </CustomButton>
                    </div>

                    <div className="counter">3 / 3</div>
                  </div>
                </div>
              </CSSTransition>

              <CSSTransition in={showItem.item === 4} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                <div className="action-content flex flex-column align-items-center">
                  <div className="actions-container flex flex-column">
                    <p className="title" dangerouslySetInnerHTML={{ __html: questionMessage[4][lang] }}></p>
                    <div className="actions flex flex-row justify-content-end flex-wrap">
                      <textarea className="open-text" onChange={updateOpenQuestion} value={answers.open} rows={answers.nbRows} placeholder={placeHolder[lang]}></textarea>
                      <div className="text-length">
                        {answers.open.length}/{limit}
                      </div>
                    </div>
                    <div className="counter">
                      <CustomButton onClick={sendReview} customstyle={buttonStyle} className="action-button bloc-noir">
                        {lang === 'fr' && 'Ok'}
                        {lang === 'nl' && 'Ok'}
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </CSSTransition>

              <CSSTransition in={showItem.item === 'last'} timeout={1000} classNames="actionsAnimation" unmountOnExit>
                <div className="action-content flex flex-column align-items-center">
                  <div className="actions-container container flex flex-column">
                    <p className="title" dangerouslySetInnerHTML={{ __html: questionMessage['last'][lang] }}></p>
                  </div>
                </div>
              </CSSTransition>
            </div>

            {showItem.item === 'last' && (
              <div className="partners flex align-items-end">
                <div className="partner-container">
                  <p>
                    {lang === 'fr' && 'UN PROJET DE'}
                    {lang === 'nl' && 'EEN PROJET VAN'}
                  </p>
                  <a target="_blank" href="https://www.caritasinternational.be/" rel="noreferrer">
                    <img className="partner-logo" src={caritasLogo} alt="" />
                  </a>
                </div>
                <div className="partner-container">
                  <a target="_blank" href="https://www.justicepaix.be/" rel="noreferrer">
                    <img className="partner-logo" src={justicePaixLogo} alt="" />
                  </a>
                </div>
                <div className="partner-container">
                  <p>
                    {lang === 'fr' && 'CONÇU PAR'}
                    {lang === 'nl' && 'ONTWORPEN DOOR'}
                  </p>
                  <a target="_blank" href="https://www.switch-asbl.org/" rel="noreferrer">
                    <img className="partner-logo" src={switchLogo} alt="" />
                  </a>
                </div>
                <div className="partner-container">
                  <p>
                    {lang === 'fr' && 'AVEC LE SOUTIEN DE'}
                    {lang === 'nl' && 'MET DE STEUN VAN'}
                  </p>
                  {lang === 'fr' && <img className="partner-logo" src={CBD_logo_FR} alt="" />}
                  {lang === 'nl' && <img className="partner-logo" src={CBD_logo_NL} alt="" />}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </FirebaseProvider>
  );
};

const CustomButton = (props) => {
  const customStyle = makeStyles((theme) => ({
    customButton: props.customstyle || {},
  }))();

  return (
    // <div className={}>
    <button disabled={props.isDisabled} onClick={props.onClick} className={clsx(customStyle.customButton, props.className)}>
      {props.children}
    </button>
    // </div>
  );
};

const SelectScore = (props) => {
  const dots = {
    1: point_1,
    2: point_2,
    3: point_3,
    4: point_4,
    5: point_5,
  };

  const { lang } = useContext(LangContext);

  const validateButtons = {
    fr: {
      oui: 'Oui, tout a fait',
      non: 'Non, pas du tout',
    },
    nl: {
      oui: 'Ja, absoluut',
      non: 'Nee, helemaal niet',
    },
  };
  return (
    <div className="flex justify-content-center score-container">
      <div className="arrow-ind left">
        <img src={arrow_eval_down} alt="" />
        {validateButtons[lang]['non']}
      </div>

      <div className="scores flex">
        {[1, 2, 3, 4, 5].map((i) => {
          return (
            <button
              key={i}
              className={clsx('dot-button', { selected: props.selected === i })}
              onClick={() => {
                props.setScore(i);
              }}
            >
              <img src={dots[i]} alt={i} />
            </button>
          );
        })}
      </div>

      <div className="arrow-ind right">
        <img src={arrow_eval_up} alt="" />
        {validateButtons[lang]['oui']}
      </div>
    </div>
  );
};
