import React from 'react';

import app from 'firebase/app';
import 'firebase/firestore';

/* CONFIG */
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

/* CLASS */
class Firebase {
  constructor() {
    app.initializeApp(config);

    this.db = app.firestore();
  }

  setReview = (review) => {
    return this.db.collection('eval').add({
      ...review,
      epilogue: Number(localStorage.getItem('epilogue')) || 0,
    });
  };

  //   setEvaluation = (reviewerUid, aapId, projectId, state) => {
  //     return this.db.collection('evaluations').doc(reviewerUid).collection(aapId).doc(projectId).set(state);
  //   };
}

/* CONTEXT */
const FirebaseApp = new Firebase();
const initialContext = {
  app: FirebaseApp,
};

const FirebaseContext = React.createContext(initialContext);

const FirebaseProvider = (props) => {
  return <FirebaseContext.Provider value={initialContext}>{props.children}</FirebaseContext.Provider>;
};

/* EXPORT */
export { FirebaseContext, FirebaseProvider };
