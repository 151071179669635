import { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation, Switch, Route, Redirect } from 'react-router-dom';

import { PageTransition } from '@steveeeie/react-page-transition';

import { PageTransitionContext } from './pageTransitionProvider';

import { LangContext } from './langProvider';

import { Homepage } from './components/homepage';
import { ChooseJourney } from './components/chooseJourney';
import { Journey } from 'components/journey';
import { About } from 'components/about';
import { OtherStories } from 'components/other_stories';
import { Review } from 'components/evaluation';

import ReactGA from "react-ga4";
import { JuniorContext } from "juniorProvider";

export const HOMEPAGE = '/';
export const CHOOSE = '/choose';
export const JOURNEY = '/journey';
export const ABOUT = '/about';
export const OTHERSTORIES = '/other-stories';
export const REVIEW = '/review';

export const JUNIOR = "/junior"

const initiliazeReactGA = () => {
  // console.log('init reactGa')
  // ReactGA.initialize('UA-210584606-1', { debug: false });
  // ReactGA.initialize('G-EW0KLH6FRR', { debug: false });
  ReactGA.initialize('GTM-WN6NZD3', { debug: false });
};

export const CustomRouting = (props) => {
  const { pathname } = useLocation();
  const { lang } = useContext(LangContext);
  const { changeJunior } = useContext(JuniorContext);
  const history = useHistory();


  useEffect(() => {
    if (pathname === JUNIOR) {
      // will be redirected to homepage
      changeJunior(true)
    }
  }, [pathname, changeJunior, history])


  if (pathname !== HOMEPAGE) {
    if (lang === '') {
      history.replace(HOMEPAGE);
    }
  }



  const [isReactGaInitilized, reactGaInitiliaze] = useState(false);

  useEffect(() => {
    if (!isReactGaInitilized) {
      initiliazeReactGA();
      reactGaInitiliaze(true);
    }
    // ReactGA.pageview(history.location.pathname);
    ReactGA.send({ hitType: "pageview", page: history.location.pathname });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const pageTransitionContext = useContext(PageTransitionContext);

  const { preset, enterAnimation, exitAnimation } = pageTransitionContext;

  return (
    <>
      {/* <Route exact path="/" render={() => <Redirect to={HOMEPAGE} />} /> */}

      <Route
        render={({ location }) => (
          <PageTransition preset={preset} enterAnimation={enterAnimation} exitAnimation={exitAnimation} transitionKey={location.pathname}>
            <Switch location={location}>
              {/* Redirect url with ending / */}
              {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}

              <Route exact path={HOMEPAGE} render={() => <Homepage />} />

              <Route exact path={CHOOSE} component={ChooseJourney} />

              <Route exact path={ABOUT} component={About} />

              <Route path={`${JOURNEY}/:name/:step`} render={() => <Journey />}></Route>

              <Route exact path={OTHERSTORIES} component={OtherStories} />

              <Route exact path={REVIEW} component={Review} />

              {/* <Route exact path="/" render={() => <Redirect to={HOMEPAGE} />} /> */}

              <Route path="*">
                <Redirect to={HOMEPAGE} />
              </Route>
            </Switch>
          </PageTransition>
        )}
      />
    </>
  );
};
