import './style.css';
import useWindowDimensions from 'useWindowDimensions';
import backgroundImg from 'assets/img/home-background-min.jpg';
import { useEffect, useState } from 'react';

const messages = {
  fr: 'Pour utiliser cette application, vous devez tourner votre écran',
  nl: 'Om deze app te gebruiken, moet je je scherm draaien',
};
export const RotateScreen = (props) => {
  const { width, height } = useWindowDimensions();

  const [showRotate, setShowRotate] = useState(width < height);
  useEffect(() => {
    setShowRotate(width < height);
  }, [width, height]);

  return (
    <div className={`rotate-screen-container ${showRotate ? 'show' : ''}`}>
      <div className="rotate-screen flex flex-column justify-content-center align-items-center background-cover" style={{ backgroundColor: 'black', backgroundImage: `url(${backgroundImg})` }}>
        <div className="rotate-content">
          {messages.fr}
          <i className="flaticon-screen-rotation-button"></i>
          {messages.nl}
        </div>
      </div>
      {props.children}
    </div>
  );
};
