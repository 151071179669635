import './style.css';
import { useMemo, useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as routes from 'routes';

import arrowDownMap from 'assets/img/arrowDownMap.svg';

import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';

export const JourneyMap = (props) => {
  const { content } = props;
  const history = useHistory();

  const pageTransitionContext = useContext(PageTransitionContext);

  const [allowGoToNext, setAllowGoToNext] = useState(false);

  useEffect(() => {
    const tt = setTimeout(() => {
      setAllowGoToNext(true);
    }, 1500);
    return () => {
      clearTimeout(tt);
    };
  }, []);

  const goingToNext = useRef(false);

  const goToNext = useMemo(() => {
    return () => {
      if (!allowGoToNext) return;
      if (goingToNext.current) return;

      goingToNext.current = true;

      const nextStepIndex = props.allSteps.findIndex((step) => step.id === props.content.nextStepId);

      let nextPageLocation, nextPageTransition;

      if (nextStepIndex < 0) {
        nextPageLocation = routes.HOMEPAGE;
        nextPageTransition = getNextTransition();
      } else {
        nextPageLocation = `${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`;
        nextPageTransition = getNextTransition(props.allSteps[nextStepIndex].type, 'map');
      }

      pageTransitionContext.handleSetAnimation(nextPageTransition).then((value) => {
        history.push(nextPageLocation);
      });

      // if (nextStepIndex < 0) history.push(routes.HOMEPAGE);
      // else history.push(`${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`);
    };
  }, [allowGoToNext, history, pageTransitionContext, props.allSteps, props.content.nextStepId, props.name]);

  // GO NEXT AUTOMATIC
  // useEffect(() => {
  //   // register automatic goToNext
  //   const timeout = setTimeout(() => {
  //     goToNext();
  //   }, content.nextStepDelay || 500);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [content, goToNext]);

  // const mapContainer = useRef()
  // useEffect(()=>{
  //   if (mapContainer.current)
  //     mapContainer.current.focus()
  // })

  const checkScroll = () => {
    goToNext();
  };

  /* Styles */
  const mapContentStyle = {
    backgroundImage: `url(${content.background})`,
  };

  const mapImageStyle = content.mapImageStyle || {};

  return (
    <div id="mapContainer" onScroll={checkScroll} onClick={goToNext}>
      <div id="map" className={`fixed-height background-cover flex flex-column align-items-center ${content.mapContainerClass || ''}`} style={mapContentStyle}>
        {props.lang && (
          <div className={`map-image flex flex-column justify-content-center align-items-center show`}>
            <img src={content.mapImage} alt="" style={mapImageStyle}/>
            {allowGoToNext && (
              <div className="arrow-down-container">
                <img src={arrowDownMap} alt="" />
              </div>
            )}
          </div>
        )}
      </div>
      {allowGoToNext && <div className="map-scroller"></div>}
    </div>
  );
};
