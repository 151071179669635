import { useContext, useState, useEffect, useRef } from 'react';
import { LangContext } from 'langProvider';
import { JuniorContext } from 'juniorProvider';
import './style.css';

import * as routes from 'routes';
import { useHistory } from 'react-router-dom';

import { stories } from 'assets/stories';

import textBlocBackground from 'assets/img/bloc_blanc.png';
import backgroundImg from 'assets/img/home-background-min.jpg';

// import mapBackground from 'assets/maps/carte_parcours_1_2_3.svg';
import mapBackground from 'assets/maps/carte_globale.svg';

import mapCongo from 'assets/maps/congo_stroke.svg';
import mapCongoFilled from 'assets/maps/congo_fill.svg';

import mapErythree from 'assets/maps/erythree_stroke.svg';
import mapErythreeFilled from 'assets/maps/erythree_fill.svg';

import mapSyrie from 'assets/maps/syrie_stroke.svg';
import mapSyrieFilled from 'assets/maps/syrie_fill.svg';
import shoesCongo from 'assets/maps/shoes_congo.svg';
import shoesErythree from 'assets/maps/shoes_erythree.svg';
import shoesSyrie from 'assets/maps/shoes_syrie.svg';

import { CSSTransition } from 'react-transition-group';

import { AudioContext } from 'audioProvider';
import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';

import ReactGA from 'react-ga4';

import backgroundSound from 'assets/sounds/LOOP MUSIC INTRO.mp3';

const infoMessage = {
  fr: 'Pour une expérience optimale, activez le son',
  nl: 'Voor een optimale ervaring, zet het geluid aan',
};
export const ChooseJourney = () => {
  const [showMap, setShowMap] = useState(false);
  const { audio, setSource, backgroundAudio, setBackgroundSource, setBackgroundAudioVolume } = useContext(AudioContext);

  const [infoVisible, setInfoVisible] = useState(null);

  // const { width, height } = useWindowDimensions();
  const { lang } = useContext(LangContext);
  const { junior } = useContext(JuniorContext);
  const history = useHistory();

  const [audioOn, setAudioOn] = useState(audio.volume === 1);

  useEffect(() => {
    setAudioOn(audio.volume === 1);
  }, [audio.volume]);

  // Start background sound
  useEffect(() => {
    setBackgroundSource(backgroundSound, true);
    setTimeout(() => {
      backgroundAudio.play();
    }, 50);
    return () => {
      // audio.pause();
      backgroundAudio.pause();
    };
  }, [setBackgroundSource, backgroundAudio]);

  const pageTransitionContext = useContext(PageTransitionContext);
  // useEffect( ()=>{
  //   pageTransitionContext.setDefault()
  // },[])

  const backgroundLoaded = () => {
    setShowMap(true);
  };

  // const aspectRatio = {
  //   '--aspect-ratio': `${width / height}`,
  // };

  let countries;
  if (junior) {
    countries = [
      { journey: 'senait_junior', name: 'erythree', stroke: mapErythree, filled: mapErythreeFilled, shoes: shoesErythree },
    ]
  }
  else {
    countries = [
      { journey: 'sifa', name: 'congo', stroke: mapCongo, filled: mapCongoFilled, shoes: shoesCongo },
      { journey: 'senait', name: 'erythree', stroke: mapErythree, filled: mapErythreeFilled, shoes: shoesErythree },
      { journey: 'hassan', name: 'syrie', stroke: mapSyrie, filled: mapSyrieFilled, shoes: shoesSyrie },
    ];
  }

  const triggerPageChange = (name) => {
    const nextPageLocation = `${routes.JOURNEY}/${name}/${1}`;
    const nextPageTransition = getNextTransition();

    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(nextPageLocation);
    });
  };
  const setJourney = (name) => {
    backgroundAudio.pause();
    setBackgroundSource('');

    if (audioTimeout.current) {
      clearTimeout(audioTimeout.current);
    }

    if (audio.paused) {
      triggerPageChange(name);
      return;
    }

    ReactGA.event({
      category: 'Navigation',
      action: `Start ${name}`,
    });

    // register page switch after pause event
    audio.addEventListener(
      'pause',
      () => {
        setSource('');
        triggerPageChange(name);
      },
      { once: true }
    );

    // pause sound to trigger
    audio.pause();
  };

  const audioTimeout = useRef();
  const showInfo = (name) => {
    if (name) setInfoVisible(name);
    else setInfoVisible(null);

    audio.pause();
    if (audioTimeout.current) {
      clearTimeout(audioTimeout.current);
    }

    if (name && stories[lang][name].description.sound) {
      setSource(stories[lang][name].description.sound);
      audioTimeout.current = setTimeout(() => {
        audioTimeout.current = null;
        audio.play();
      }, 1300);
    }
  };

  const switchVolume = () => {
    if (audioOn) {
      audio.volume = 0;
      backgroundAudio.volume = 0;
      setAudioOn(false);
    } else {
      audio.volume = 1;
      setBackgroundAudioVolume();
      // backgroundAudio.volume=0.75
      setAudioOn(true);
    }
  };

  const openAbout = () => {
    const nextPageTransition = getNextTransition('about');
    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(routes.ABOUT);
    });
  };

  return (
    <main className="main-choose fixed-height fixed-width background-cover" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className={`background-container ${showMap ? 'show' : ''}`}>
        <div className="background-map">
          <img
            className="cursor-gray"
            src={mapBackground}
            onLoad={backgroundLoaded}
            alt=""
            onClick={() => {
              showInfo();
            }}
          />

          {countries.map((c) => {
            return (
              <button
                key={c.name}
                className={`button-country button-${c.name} ${infoVisible === c.journey ? 'hovered' : ''}`}
                onClick={() => {
                  // setJourney(c.journey);
                  showInfo(c.journey);
                }}
              >
                <img src={c.stroke} alt="" />
                <img className="on-hover" src={c.filled} alt="" />

                <div className="shoes-container">
                  <img src={c.shoes} alt="" />
                </div>
              </button>
            );
          })}
        </div>

        <CSSTransition in={infoVisible !== null} timeout={300} classNames="info-box" unmountOnExit>
          <JourneyInfo info={(infoVisible && stories[lang][infoVisible]) || {}} name={infoVisible} lang={lang} start={setJourney} />
        </CSSTransition>

        <div className="info">
          <button className={`background-cover sound ${audioOn ? 'on' : 'off'}`} onClick={switchVolume}></button> {infoMessage[lang]}
        </div>
      </div>

      <div className="more-container">
        <button className="more-button " onClick={openAbout}>
          <div className="background-cover small-circular-button more-button"></div>
        </button>
      </div>
    </main>
  );
};

const JourneyInfo = (props) => {
  const { lang, info } = props;

  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setShowText(false);
    const tt = setTimeout(() => {
      setShowText(true);
    }, 300);

    return () => {
      clearTimeout(tt);
    };
  }, [info.description]);

  if (!info.description) {
    return <></>;
  }
  return (
    <div className={`story-container ${info.description.name}`} style={{ backgroundImage: `url(${textBlocBackground})` }}>
      <div className={`scroll-container ${showText ? 'show' : ''}`}>
        <div className="story-title">
          {info.description && (
            <>
              <div>
                <span style={{ textTransform: 'uppercase' }}>{info.description.name}</span>, {info.description.age} {lang === 'fr' ? 'ans' : 'jaar'}
              </div>

              <div className="story-city">
                {info.description.country} &bull; <span style={{ textTransform: 'initial' }}>{info.description.city}</span>
              </div>
            </>
          )}
        </div>

        <button className="start-button bloc-noir" onClick={() => props.start(props.name)}>
          {lang === 'fr' && 'COMMENCER'}
          {lang === 'nl' && 'START'}
        </button>

        <div className="story-text">{info.description && info.description.text}</div>
      </div>
    </div>
  );
};
