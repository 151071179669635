import './style.css';

import homeImg from 'assets/img/home-icon.png';
import homeHoverImg from 'assets/img/home-icon-hoover.png';

import { useHistory } from 'react-router-dom';
import * as routes from 'routes';
import { useState, useMemo, useEffect, useRef, useContext, useCallback } from 'react';

import useWindowDimensions from 'useWindowDimensions';

import { AudioContext } from 'audioProvider';
import { PageTransitionContext, getNextTransition } from 'pageTransitionProvider';
import { LangContext } from 'langProvider';

import arrowDownMap from 'assets/img/arrowDownMap.svg';

var Timer = function (callback, delay) {
  var timerId = null;
  var start = null;
  var remaining = delay;
  var paused = false;

  this.pause = function () {
    window.clearTimeout(timerId);
    remaining -= Date.now() - start;
    paused = true;
  };

  this.resume = function (force = false) {
    if (remaining <= 0) return;
    if (!paused & !force) return;

    start = Date.now();
    window.clearTimeout(timerId);
    timerId = window.setTimeout(() => {
      callback();
      remaining = 0;
    }, remaining);
  };

  this.clear = function () {
    window.clearTimeout(timerId);
  };

  this.resume(true);
};

const ADD_SOUND_DELAY = 0;
const ADD_OTHER_DELAY = 50;

export const Sequence = (props) => {
  const { content, skipAutoNextStep, soundsInLoop } = props;
  const history = useHistory();
  const { width, height } = useWindowDimensions();

  const { audio, setSource, setLoop } = useContext(AudioContext);

  const [text, setText] = useState('');

  const { lang } = useContext(LangContext);

  const pageTransitionContext = useContext(PageTransitionContext);

  const [playing, setPlaying] = useState(true);
  const [audioOn, setAudioOn] = useState(audio.volume === 1);


  /* Styles */
  // const sequenceContainer = {
  //   backgroundImage: `url(${content.background})`,
  // };
  const goNextStep = useMemo(() => {
    return () => {
      const nextStepIndex = props.allSteps.findIndex((step) => step.id === props.content.nextStepId);

      let nextPageLocation, nextPageTransition;
      if (nextStepIndex < 0) {
        nextPageLocation = routes.HOMEPAGE;
        nextPageTransition = getNextTransition();
      } else {
        nextPageLocation = `${routes.JOURNEY}/${props.name}/${nextStepIndex + 1}`;
        nextPageTransition = getNextTransition(props.allSteps[nextStepIndex].type, 'sequence');
      }

      pageTransitionContext.handleSetAnimation(nextPageTransition).then((value) => {
        history.push(nextPageLocation);
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, props.allSteps, props.content.nextStepId, props.name]);

  const goBackHome = () => {
    const nextPageTransition = getNextTransition();

    pageTransitionContext.handleSetAnimation(nextPageTransition).then(() => {
      history.push(routes.HOMEPAGE);
    });
  };

  const sequence = useMemo(() => {
    return props.content.sequence || [];
  }, [props.content.sequence]);

  const sound = useMemo(() => {
    return (props.content.sound && props.content.sound) || null;
  }, [props.content.sound]);

  const soundDelay = useMemo(() => {
    return (props.content.sound_delay && props.content.sound_delay) || 0;
  }, [props.content.sound_delay]);

  const currentTimeouts = useRef([]);
  //   const audioPlayback = useRef();

  const pausingWhileLoading = useCallback((event) => {
    if (audio.src.includes(sound)) {
      pausingImageTransition();
      if (currentTimeouts.current) {
        currentTimeouts.current.forEach((CT) => CT.pause());
      }
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resumeAfterLoading = useCallback((event) => {
    if (audio.src.includes(sound)) {
      resumeImageTransition();

      if (currentTimeouts.current) {
        currentTimeouts.current.forEach((CT) => CT.resume());
      }
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCueChange = useCallback(() => {
    let cues = audio.textTracks[0].activeCues;
    if (cues.length === 0) {
      setText('');
    } else {
      setText(cues[0].text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Manage iteration on sequence */
  useEffect(() => {
    const timeoutToCancel = [];

    if (sound) {
      //   audioPlayback.current = new Audio(sound);
      const audioTimeout = new Timer(() => {
        if (!audio.paused) {
          audio.pause();
        }

        setSource(sound);

        if (soundsInLoop) {
          setLoop(soundsInLoop)
        }

        const track = audio.textTracks[0];
        // clear previous cues
        if (track.cues.length > 0) {
          while (track.cues.length) {
            track.removeCue(track.cues[0]);
          }
        }

        let track_delay = 0;
        for (let i = 0; i < sequence.length - 1; i++) {
          track_delay += sequence[i].delay;
          track.addCue(new VTTCue(track_delay / 1000, (track_delay + sequence[i + 1].delay) / 1000, sequence[i].text));
        }

        track.addEventListener('cuechange', handleCueChange);

        audio.play();

        audio.addEventListener('playing', resumeAfterLoading);
        audio.addEventListener('waiting', pausingWhileLoading);

        if (!skipAutoNextStep) {
          audio.addEventListener('ended', goNextStep, { 'once': true });
        }

      }, soundDelay + ADD_SOUND_DELAY);

      timeoutToCancel.push(audioTimeout);
    }

    timeoutToCancel.forEach((ttc) => {
      currentTimeouts.current.push(ttc);
    });

    return () => {
      setText('');
      // setTextBackground(null);

      if (sound) {
        if (audio.src.includes(sound)) {
          audio.pause();
          setSource('');
          setLoop(false)
        }

        audio.textTracks[0].removeEventListener('cuechange', handleCueChange);
        audio.removeEventListener('playing', resumeAfterLoading);
        audio.removeEventListener('waiting', pausingWhileLoading);

        if (!skipAutoNextStep) {
          audio.removeEventListener('ended', goNextStep);
        }

      }

      timeoutToCancel.forEach((TTC) => TTC.clear());
    };
  }, [sequence, skipAutoNextStep, soundsInLoop, goNextStep, sound, soundDelay, audio, setSource, setLoop, props.lang, pausingWhileLoading, resumeAfterLoading, handleCueChange]);

  /*  pausing on screen shift */
  useEffect(() => {
    if (width < height) {
      if (playing) {
        pausingImageTransition();
        if (sound && !audio.paused) audio.pause();
        if (currentTimeouts.current) {
          currentTimeouts.current.forEach((CT) => CT.pause());
        }
        setPlaying(false);
      }
    } else {
      if (!playing) {
        resumeImageTransition();
        if (sound && audio.src && audio.paused && audio.played.length) {
          audio.play();
        }
        if (currentTimeouts.current) {
          currentTimeouts.current.forEach((CT) => CT.resume());
        }
        setPlaying(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, sound, audio]);

  /* Manage Background Image and Effects */

  const [backgroundImage, setBackgroundImage] = useState({
    src: (content.background && content.background.src) || null,
    style: (content.background && content.background.style) || {},
  });
  const [backgroundEffect, setBackgroundEffect] = useState({
    style: (content.backgroundEffect && content.backgroundEffect.style) || {},
  });

  const [foregroundImage, setForegroundImage] = useState({
    src: (content.foreground && content.foreground.src) || null,
    style: (content.foreground && content.foreground.style) || {},
  });

  /* BACKGROUND & EFFECT */
  useEffect(() => {
    // let delay = 0;
    let delay = ADD_OTHER_DELAY;

    if (!content.background || !content.background.animations) return;

    const timeoutToCancel = [];
    content.background.animations.forEach((seq) => {
      delay += seq.delay;

      const backgroundTimeout = new Timer(() => {
        setBackgroundImage((curBack) => ({
          src: curBack.src,
          style: {
            ...(content.background.style || {}),
            ...(seq.style || {}),
          },
        }));
      }, delay);
      timeoutToCancel.push(backgroundTimeout);
    });

    timeoutToCancel.forEach((ttc) => {
      currentTimeouts.current.push(ttc);
    });

    return () => {
      timeoutToCancel.forEach((TTC) => TTC.clear());
    };
  }, [content.background]);

  useEffect(() => {
    // let delay = 0;
    let delay = ADD_OTHER_DELAY;

    if (!content.backgroundEffect || !content.backgroundEffect.animations) return;

    const timeoutToCancel = [];
    content.backgroundEffect.animations.forEach((seq) => {
      delay += seq.delay;

      const backgroundEffectTimeout = new Timer(() => {
        setBackgroundEffect((curBack) => ({
          style: {
            ...(content.backgroundEffect.style || {}),
            ...(seq.style || {}),
          },
        }));
      }, delay);
      timeoutToCancel.push(backgroundEffectTimeout);
    });

    timeoutToCancel.forEach((ttc) => {
      currentTimeouts.current.push(ttc);
    });

    return () => {
      timeoutToCancel.forEach((TTC) => TTC.clear());
    };
  }, [content.backgroundEffect]);

  /* FOREGROUND EFFECT */
  useEffect(() => {
    // let delay = 0;
    let delay = ADD_OTHER_DELAY;

    if (!content.foreground || !content.foreground.animations) return;

    const timeoutToCancel = [];
    content.foreground.animations.forEach((seq) => {
      delay += seq.delay;

      const foregroundTimeout = new Timer(() => {
        setForegroundImage((curBack) => ({
          src: seq.src || curBack.src,
          style: {
            ...(content.foreground.style || {}),
            ...(seq.style || {}),
          },
        }));
      }, delay);
      timeoutToCancel.push(foregroundTimeout);
    });


    timeoutToCancel.forEach((ttc) => {
      currentTimeouts.current.push(ttc);
    });

    return () => {
      timeoutToCancel.forEach((TTC) => TTC.clear());
    };
  }, [content.foreground]);

  const textBackgroundColor = content.textBackgroundColor || 'rgba(0,0,0,1)';

  const pausingImageTransition = () => {
    setBackgroundImage((curBack) => ({
      src: curBack.src,
      style: { ...curBack.style, animationPlayState: 'paused' },
    }));
    setBackgroundEffect((curBack) => ({
      style: { ...curBack.style, animationPlayState: 'paused' },
    }));
    setForegroundImage((curBack) => ({
      src: curBack.src,
      style: { ...curBack.style, animationPlayState: 'paused' },
    }));
  };
  const resumeImageTransition = () => {
    setBackgroundImage((curBack) => ({
      src: curBack.src,
      style: { ...curBack.style, animationPlayState: 'running' },
    }));

    setBackgroundEffect((curBack) => ({
      style: { ...curBack.style, animationPlayState: 'running' },
    }));
    setForegroundImage((curBack) => ({
      src: curBack.src,
      style: { ...curBack.style, animationPlayState: 'running' },
    }));
  };
  const switchPlaying = () => {
    if (audio.played.length === 0) {
      return;
    }
    if (playing) {
      pausingImageTransition();
      if (sound && !audio.paused) audio.pause();
      if (currentTimeouts.current) {
        currentTimeouts.current.forEach((CT) => CT.pause());
      }
      setPlaying(false);
    } else {
      resumeImageTransition();

      if (sound && audio.paused) {
        audio.play();
      }
      if (currentTimeouts.current) {
        currentTimeouts.current.forEach((CT) => CT.resume());
      }
      setPlaying(true);
    }
  };

  const switchVolume = () => {
    if (audioOn) {
      audio.volume = 0;
      setAudioOn(false);
    } else {
      audio.volume = 1;
      setAudioOn(true);
    }
  };

  return (
    <div id="sequence" className={`background-cover flex flex-column align-items-center ${content.sequenceContainerClass || ''}`}>
      {props.lang && (
        <>
          <div className="background">
            {backgroundImage.src && <img className="background-image" src={backgroundImage.src} alt="" style={backgroundImage.style} />}
            <div className="background-effect" style={backgroundEffect.style}></div>
          </div>

          <div className="foreground flex flex-column justify-content-center">
            {foregroundImage.src && <img className="background-image" src={foregroundImage.src} alt="" style={foregroundImage.style} />}
          </div>
          <div className="control-container flex">
            <button className="go-back-home-button" onClick={goBackHome}>
              <span className="info">{'<'}&nbsp;Home </span> <img src={homeImg} alt="" /> <img className="on-hover" src={homeHoverImg} alt="" />
            </button>

            {skipAutoNextStep ? <button className="noauto skip-sequence-button" onClick={goNextStep}>
              {lang === 'fr' && 'Avancer'}
              {lang === 'nl' && 'Verdergaan'}&nbsp;<span id="carret"><img src={arrowDownMap} alt="" /></span>
            </button> :
              <button className="skip-sequence-button" onClick={goNextStep}>
                {lang === 'fr' && 'Sauter la séquence'}
                {lang === 'nl' && 'Reeks overslaan'}&nbsp;<span id="carret">{'>'}</span>
              </button>
            }
          </div>

          {/* <div className={`additional-image flex flex-column justify-content-center ${shownTextBackground.show ? 'show' : ''}`}>
            <img src={shownTextBackground.src} alt="" style={shownTextBackground.style || {}} />
          </div> */}

          <div className={`text-background-overlay`} style={{ '--text-background-color': textBackgroundColor }}></div>
          <div className={`text-container ${text ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: text }}></div>

          <div className="controller">
            <button className={`background-cover play-pause ${playing ? 'paused' : 'playing'}`} onClick={switchPlaying}></button>
            <button className={`background-cover sound ${audioOn ? 'on' : 'off'}`} onClick={switchVolume}></button>
          </div>
        </>
      )}
    </div>
  );
};
